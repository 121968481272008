<template>
    <div class="card">
                    <div class="card-header">
                        <h5>Font Weight</h5>
                    </div>
                    <div class="card-body">
                        <pre class="helper-classes">.f-w-100 {
font-weight: 100;
}
.f-w-300 {
font-weight: 300;
}
.f-w-400 {
font-weight: 400;
}
.f-w-600 {
font-weight: 600;
}
.f-w-700 {
font-weight: 700;
}
.f-w-900 {
font-weight: 900;
}</pre>
                    </div>
                </div>
</template>