export const shipped=[
    {
        src:"product-table-6.png",
        product:"Women Top",
        price: '210$',
        stock:'In stock',
    },
    {
        src:"product-table-5.png",
        product:"Women Shorts",
        price: '210$',
        stock:'In stock',
    },
    {
        src:"product-table-4.png",
        product:"Cyclamen",
        price: '210$',
        stock:'In stock',
    },{
        src:"product-table-3.png",
        product:"Man Solid Denim Jacket",
        price: '210$',
        stock:'In stock',
    },{
        src:"product-table-2.png",
        product:"Blue Shirt",
        price: '210$',
        stock:'In stock',
    },{
        src:"product-table-1.png",
        product:"",
        price: '210$',
        stock:'In stock',
    },{
        src:"product-table-1.png",
        product:"Red Shirt",
        price: '210$',
        stock:'In stock',
    },{
        src:"product-table-6.png",
        product:"Women Top",
        price: '210$',
        stock:'In stock',
    },{
        src:"product-table-5.png",
        product:"Women Short",
        price: '210$',
        stock:'In stock',
    }
]