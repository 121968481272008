<template>
    <div class="col-xxl-4 col-md-6 appointment-sec box-col-6">
        <div class="appointment">
            <div class="card">
                <div class="card-header card-no-border">
                    <div class="header-top">
                        <h5 class="m-0">Recent Registrations</h5>
                    </div>
                </div>
                 <div class="card-body pt-0" v-if="recentRegistrations != null">
                    <div class="appointment-table table-responsive">
                        <table class="table table-bordernone">
                            <tbody>
                                <tr v-for="item in recentRegistrations" :key="item">

                                    <td class="img-content-box">
                                        <router-link class="d-block f-w-500" to="/app/socialPage"></router-link>{{ item.username
                                        }}<span class="f-light">{{ formatDate(item.createdAt) }}</span>
                                    </td>
                                    <td class="text-end">
                                        <p class="m-0 font-success">{{ item.promoCode }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>

 <script>
import{mapState, mapActions} from 'vuex'

export default {
    data() {
        return {
            // users: {
            //     total: 1,
            //     deltaToday: 3,
            // },
            
        }
    },
  computed: {
    ...mapState({
        recentRegistrations: (state) => state.dashboard.recentRegistrations,
    }),
  },
  methods: {
    formatDate(date) {
        console.log(date);
        return new Date(date).toLocaleDateString();
    },
  }, 
};
</script>
