import { onAuthStateChanged, getAuth } from 'firebase/auth';

export async function getUserToken() {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          resolve(token);
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(null);
      }
      unsub();
    });
  });
}