<template>
    <Breadcrumbs title="User Profile" main="Users" />


    <div class="container-fluid" v-if="user">
        <div class="user-profile">
            <div class="loader-wrapper" v-if="loading">
              <div class="loader-index">
                <span>

                </span>
              </div>
            </div>
            <div class="row" v-else>
                <personalInfo :user="user"/>
                <watchers :data="user.watchers" />
                <rooms :data="user.messages" />
                <analysis :data="user.analysis" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import personalInfo from './personalInfo.vue';
import rooms from './rooms.vue';
import watchers from './watchers.vue';
import analysis from './analysis.vue';
import tourProfile2 from './tourProfile2.vue';
import tourProfile3 from './tourProfile3.vue';
import tourProfile4 from './tourProfile4.vue';
import tourProfile5 from './tourProfile5.vue';

export default {
  data() {
    return {
      loading: true,
      user: null
    };
  },
    components: {
        personalInfo,
        rooms,
        watchers,
        analysis,
        tourProfile2,
        tourProfile3,
        tourProfile4,
        tourProfile5
    },
  name: 'userProfile',
  computed: {
    ...mapGetters('users', ['userProfile']), // Map the userProfile getter from the users module
    user() {
      return this.userProfile; // Access the userProfile data from the store
    },
  },
  created() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      const id = this.$route.params.id;
      console.log('Fetching user data for id:', id);
      this.$store.dispatch('users/fetchUser', id)
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    },
  },
  // clear the user data when the component is destroyed
  destroyed() {
    this.$store.commit('users/setUserProfile', null);
  }
};
</script>