<template>
  <a class="nav-link" target="_blank" href="https://1.envato.market/3GVzd">
    <div>
      <i class="icon-shopping-cart-full"></i>
    </div>
    <span>Buy now</span>
  </a>
</template>

<script>
  export default {
    name: 'BuyNow',
  };
</script>
