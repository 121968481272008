<template>
     <div class="col-xl-6 xl-60 col-md-7 box-col-8e">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="new-users-social">
                                <div class="media"><img class="rounded-circle image-radius m-r-15" src="../../../assets/images/user/1.jpg" alt="">
                                  <div class="media-body">
                                    <h6 class="mb-0 f-w-700">ELANA</h6>
                                    <p>January, 12,2023</p>
                                  </div><span class="pull-right mt-0"><i data-feather="more-vertical"></i></span></div>
                              </div><img class="img-fluid" alt="" src="../../../assets/images/social-app/timeline-1.png">
                              <div class="timeline-content">
                                <p>The only way to do something in depth is to work hard. I've always thought of the T-shirt as the Alpha of the fashion alphabet. My breakfast is very important. Everything I do is a matter of heart, body and soul.</p>
                                <div class="like-content mt-2"><span><i class="fa fa-heart font-danger"></i></span><span class="pull-right comment-number"><span>20 </span><span><i class="fa fa-share-alt me-0"></i></span></span><span class="pull-right comment-number"><span>10 </span><span><i class="fa fa-comments-o"></i></span></span></div>
                                <div class="social-chat">
                                  <div class="your-msg">
                                    <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/1.jpg">
                                      <div class="media-body"><span class="f-w-600">Jason Borne <span>1 Year Ago <i class="fa fa-reply font-primary"></i></span></span>
                                        <p>we are working for the dance and sing songs. this car is very awesome for the youngster. please vote this car and like our post</p></div>
                                    </div>
                                  </div>
                                  <div class="other-msg">
                                    <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/2.png">
                                      <div class="media-body"><span class="f-w-600">Alexendra Dhadio <span>1 Month Ago <i class="fa fa-reply font-primary"></i></span></span>
                                        <p>yes, really very awesome car i see the features of this car in the official website of #Mercedes-Benz and really impressed :-)</p></div>
                                    </div>
                                  </div>
                                  <div class="other-msg">
                                    <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/3.png">
                                      <div class="media-body"><span class="f-w-600">Olivia Jon <span>15 Days Ago <i class="fa fa-reply font-primary"></i></span></span>
                                        <p>i like lexus cars, lexus cars are most beautiful with the awesome features, but this car is really outstanding than lexus</p></div>
                                    </div>
                                  </div>
                                  <div class="your-msg">
                                    <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/1.jpg">
                                      <div class="media-body"><span class="f-w-600">Issa Bell <span>1 Year Ago <i class="fa fa-reply font-primary"></i></span></span>
                                        <p>we are working for the dance and sing songs. this car is very awesome for the youngster. please vote this car and like our post</p></div>
                                    </div>
                                  </div>
                                  <div class="text-center"><a class="f-w-600" href="javascript:void(0)">More Commnets</a></div>
                                </div>
                                <div class="comments-box">
                                  <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/1.jpg">
                                    <div class="media-body">
                                      <div class="input-group text-box">
                                        <input class="form-control input-txt-bx" type="text" name="message-to-send" placeholder="Post Your commnets">
                                        <div class="input-group-text"><img src="../../../assets/images/smiley.png" alt=""></div></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="new-users-social">
                                <div class="media"><img class="rounded-circle image-radius m-r-15" src="../../../assets/images/user/1.jpg" alt="">
                                  <div class="media-body">
                                    <h6 class="mb-0 f-w-700">ELANA</h6>
                                    <p>January, 12,2023</p>
                                  </div><span class="pull-right mt-0"><i data-feather="more-vertical"></i></span></div>
                              </div><img class="img-fluid" alt="" src="../../../assets/images/social-app/timeline-2.png">
                              <div class="timeline-content">
                                <p>The only way to do something in depth is to work hard. I've always thought of the T-shirt as the Alpha of the fashion alphabet. My breakfast is very important. Everything I do is a matter of heart, body and soul.</p>
                                <div class="like-content"><span><i class="fa fa-heart font-danger"></i></span><span class="pull-right comment-number"><span>20 </span><span><i class="fa fa-share-alt me-0"></i></span></span><span class="pull-right comment-number"><span>10 </span><span><i class="fa fa-comments-o"></i></span></span></div>
                                <div class="social-chat">
                                  <div class="your-msg">
                                    <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/1.jpg">
                                      <div class="media-body"><span class="f-w-600">Jason Borne <span>1 Year Ago <i class="fa fa-reply font-primary"></i></span></span>
                                        <p>we are working for the dance and sing songs. this car is very awesome for the youngster. please vote this car and like our post</p></div>
                                    </div>
                                  </div>
                                  <div class="your-msg">
                                    <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/1.jpg">
                                      <div class="media-body"><span class="f-w-600">Issa Bell <span>1 Year Ago <i class="fa fa-reply font-primary"></i></span></span>
                                        <p>we are working for the dance and sing songs. this car is very awesome for the youngster. please vote this car and like our post</p></div>
                                        </div>
                                  </div>
                                  <div class="text-center"><a class="f-w-600" href="javascript:void(0)">More Commnets</a></div>
                                </div>
                                <div class="comments-box">
                                  <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt="" src="../../../assets/images/user/1.jpg">
                                    <div class="media-body">
                                      <div class="input-group text-box">
                                        <input class="form-control input-txt-bx" type="text" name="message-to-send" placeholder="Post Your commnets">
                                        <div class="input-group-text"><img src="../../../assets/images/smiley.png" alt=""></div> </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
</template>