<template>
    <Breadcrumbs title="Promo Codes" main="Page" />
    <div class="container-fluid">
      <div class="row">
        
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>
                Promo Codes
              </h5>
              <span>
                List of all users grouped by promo code
              </span>
            </div>
           
          </div>

        </div>
        <div class="col-md-12" v-for="promocode in promocodes" :key="promocode.id">
            <div class="card">
              <div class="card-header">
                <div class="card-header-right">
                <h5>{{ promocode.promoName }}</h5>
                </div>
                <h5>{{promocode.promoCode}} ({{ promocode.userCount }})</h5>
                </div>
                    <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="user in promocode.users" :key="user.id">
                            {{user.username}} - {{user.firstName}} - {{user.lastName}} - {{user.phone}}
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: 'Promo Codes Page',
    computed: {
      ...mapGetters({
        promocodes: 'users/groupsByPromoCode',
      }),
    }, 
    methods: {
      ...mapActions('users', ['fetchGroupsByPromoCode']),
    },
    created() {
      this.fetchGroupsByPromoCode();
      console.log(this.promocodes)
    },
  };
  </script>