<template>
    <div class="col-xl-6">
                <div class="card">
                  <div class="card-header">
                    <h5>Shape</h5>
                  </div>
                  <div class="card-body avatar-showcase">
                    <div class="avatars">
                      <div class="avatar"><img class="img-100 b-r-8" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar"><img class="img-90 b-r-30" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar"><img class="img-80 b-r-35" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar"><img class="img-70 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar"><img class="img-60 b-r-25" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar"><img class="img-50 b-r-15" src="@/assets/images/user/1.jpg" alt="#"></div>
                    </div>
                  </div>
                </div>
              </div>
</template>