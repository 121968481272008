<template>
    <div class="tab-pane fade show active" id="timeline" role="tabpanel" aria-labelledby="timeline">
                <myProfile/>
      </div>
</template>
<script>
import myProfile from './myProfile.vue'
export default {
  components:{
    myProfile
  }
}
</script>