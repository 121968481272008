<template>
<div class="col-sm-12">
    <div class="card">
        <div class="card-header">
            <h5>Nesting Column</h5>
        </div>
        <div class="card-body grid-showcase">
            <p>Use flexbox alignment utilities to vertically and horizontally align columns.</p>
            <div class="row">
                <div class="col-sm-9"><span>Level 1: .col-sm-9</span>
                    <div class="row">
                        <div class="col-8 col-sm-6"><span>Level 2: .col-8 .col-sm-6</span></div>
                        <div class="col-4 col-sm-6"><span>Level 2: .col-4 .col-sm-6</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
