<template>
    <section class="comment-box">
        <h4>Comment </h4>
        <hr>
        <ul>
            <li>
                <div class="media align-self-center"><img class="align-self-center"
                        src="../../assets/images/blog/comment.jpg" alt="Generic placeholder image">
                    <div class="media-body">
                        <div class="row">
                            <div class="col-md-4 xl-100">
                                <h6 class="mt-0">Jolio Mark<span> ( Designer )</span></h6>
                            </div>
                            <div class="col-md-8 xl-100">
                                <ul class="comment-social float-start float-md-end learning-comment">
                                    <li class="digits"><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                    <li class="digits"><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                                </ul>
                            </div>
                        </div>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                            alteration in some form, by injected humour, or randomised words which don't look even slightly
                            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                            anything embarrassing hidden in the middle of text.</p>
                    </div>
                </div>
            </li>
            <li>
                <ul>
                    <li>
                        <div class="media"><img class="align-self-center" src="../../assets/images/blog/9.jpg"
                                alt="Generic placeholder image">
                            <div class="media-body">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <h6 class="mt-0">Jolio Mark<span> ( Designer )</span></h6>
                                    </div>
                                </div>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                    suffered alteration in some form, by injected humour, or randomised words which don't
                                    look even slightly believable. If you are going to use a passage of Lorem Ipsum, you
                                    need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
            <li>
                <div class="media"><img class="align-self-center" src="../../assets/images/blog/4.jpg"
                        alt="Generic placeholder image">
                    <div class="media-body">
                        <div class="row">
                            <div class="col-md-4 xl-100">
                                <h6 class="mt-0">Jolio Mark<span> ( Designer )</span></h6>
                            </div>
                            <div class="col-md-8 xl-100">
                                <ul class="comment-social float-start float-md-end learning-comment">
                                    <li class="digits"><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                    <li class="digits"><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                                </ul>
                            </div>
                        </div>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                            alteration in some form, by injected humour, or randomised words which don't look even slightly
                            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                            anything embarrassing hidden in the middle of text.</p>
                    </div>
                </div>
            </li>
        </ul>
    </section>
</template>