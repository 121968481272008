<template>
    <div class="col-sm-6 col-xl-3 col-lg-6 box-col-6"> 
        <div class="card widget-1">
          <div class="card-body"> 
            <div class="widget-content">
              <div class="widget-round secondary">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#cart"> </use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
                  </svg>
                </div>
              </div>
              <div> 
                <h4>10,000</h4><span class="f-light">Purchase</span>
              </div>
            </div>
            <div class="font-secondary f-w-500"><i class="icon-arrow-up icon-rotate me-1"></i><span>+50%</span></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6 box-col-6"> 
        <div class="card widget-1">
          <div class="card-body"> 
            <div class="widget-content">
              <div class="widget-round primary">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#tag"> </use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
                  </svg>
                </div>
              </div>
              <div> 
                <h4>4,200</h4><span class="f-light">Sales</span>
              </div>
            </div>
            <div class="font-primary f-w-500"><i class="icon-arrow-up icon-rotate me-1"></i><span>+70%</span></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6 box-col-6"> 
        <div class="card widget-1">
          <div class="card-body"> 
            <div class="widget-content">
              <div class="widget-round warning">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#return-box"> </use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
                  </svg>
                </div>
              </div>
              <div> 
                <h4>7000</h4><span class="f-light">Sales return</span>
              </div>
            </div>
            <div class="font-warning f-w-500"><i class="icon-arrow-down icon-rotate me-1"></i><span>-20%</span></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6 box-col-6"> 
        <div class="card widget-1">
          <div class="card-body"> 
            <div class="widget-content">
              <div class="widget-round success">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#rate"> </use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
                  </svg>
                </div>
              </div>
              <div> 
                <h4>5700</h4><span class="f-light">Purchase rate</span>
              </div>
            </div>
            <div class="font-success f-w-500"><i class="icon-arrow-up icon-rotate me-1"></i><span>+70%</span></div>
          </div>
        </div>
      </div>
</template>