<template>
  <Breadcrumbs title="Users" main="Page" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>List of Users</h5>
            <span>
              List of all users
            </span>
            <div class="card-header-right">
              <h5>Total {{ users.length }}</h5>
            </div>
          </div>
          <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="border-bottom-primary">
              <th scope="col">Username</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Platform</th>
              <th scope="col">Phone</th>
              <th scope="col">PromoCode</th>
              <th scope="col">Created</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="item.borderColor" v-for="(item, index) in users" :key="index">
              <td>{{ item.email }}</td>
              <td>{{ item.firstName }}</td> 
              <td>{{ item.lastName }}</td>
              <td>{{ item.platform }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.promoCode }}</td>
              <td>{{ formatDate(item.createdAt)}}</td>
              <td>
                <router-link :to="{ name: 'profile', params: { id: item._id } }" class="btn btn-primary btn-sm">View</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UserPage',
  computed: {
    ...mapGetters({
      users: 'users/users',
    }),
  },
  methods: {
    ...mapActions('users', ['fetchUsers']),
    formatDate(date) {
      // get date in format 10.10.2021 10:10:10
      return new Date(date).toLocaleString();
    },
  },
  created() {
    this.fetchUsers();
    console.log(this.users)
  },
};
</script>