<template>
  <div class="col-xl-4 xl-40 col-lg-6 col-md-5">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="card card-mb-faq xs-mt-search">
                          <div class="card-header faq-header pb-0">
                            <h5 >Search articles</h5><vue-feather type="help-circle"></vue-feather>
                          </div>
                          <div class="card-body faq-body">
                            <div class="faq-form">
                              <input class="form-control" type="text" placeholder="Search.."><vue-feather class="search-icon" type="search"></vue-feather>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="card card-mb-faq">
                          <div class="card-header faq-header pb-0">
                            <h5 >Navigation</h5><vue-feather type="settings"></vue-feather>
                          </div>
                          <div class="card-body faq-body">
                            <div class="navigation-btn"><a class="btn btn-primary" href="javascript:void(0)"><i class="m-r-10" data-feather="message-square"><vue-feather type="message-square"></vue-feather></i>Ask Question</a></div>
                            <div class="navigation-option">
                              <ul>
                                <li><a href="javascript:void(0)"><i data-feather="edit"><vue-feather type="edit"></vue-feather></i>Tutorials</a></li>
                                <li><a href="javascript:void(0)"><i data-feather="globe"><vue-feather type="globe"></vue-feather></i>Help center</a></li>
                                <li><a href="javascript:void(0)"><i data-feather="book-open"><vue-feather type="book-open"></vue-feather></i>Knowledgebase</a></li>
                                <li><a href="javascript:void(0)"><i data-feather="file-text"><vue-feather type="file-text"></vue-feather></i>Articles</a><span class="badge badge-primary rounded-pill pull-right">42</span></li>
                                <li><a href="javascript:void(0)"><i data-feather="youtube"><vue-feather type="youtube"></vue-feather></i>Video Tutorials</a><span class="badge badge-primary rounded-pill pull-right">648</span></li>
                                <li><a href="javascript:void(0)"><i data-feather="message-circle"><vue-feather type="message-circle"></vue-feather></i>Ask our community</a></li>
                                <li><a href="javascript:void(0)"><i data-feather="mail"><vue-feather type="mail"></vue-feather></i>Contact us</a></li>
                              </ul>
                              <ul>
                                <li><a href="javascript:void(0)"><i data-feather="message-circle"><vue-feather type="message-circle"></vue-feather></i>Ask our community</a></li>
                                <li><a href="javascript:void(0)"><i data-feather="mail"><vue-feather type="mail"></vue-feather></i>Contact us</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-header faq-header pb-0">
                            <h5 >Latest Updates</h5><span class="pull-right d-inline-block">See All</span>
                          </div>
                          <div class="card-body faq-body">
                            <div class="media updates-faq-main">
                              <div class="updates-faq"><vue-feather  class="font-primary" type="rotate-cw"></vue-feather></div>
                              <div class="media-body updates-bottom-time">
                                <p><a href="javascript:void(0)">David Linner </a>requested money back for a double debit card charge</p>
                                <p>10 minutes ago</p>
                              </div>
                            </div>
                            <div class="media updates-faq-main">
                              <div class="updates-faq"><vue-feather class="font-primary" type="dollar-sign"></vue-feather></div>
                              <div class="media-body updates-bottom-time">
                                <p>All sellers have received monthly payouts</p>
                                <p>2 hours ago</p>
                              </div>
                            </div>
                            <div class="media updates-faq-main">
                              <div class="updates-faq"><vue-feather class="font-primary" type="link"></vue-feather></div>
                              <div class="media-body updates-bottom-time">
                                <p>User Christopher <a href="javascript:void(0)">Wallace</a> is on hold and awaiting for staff reply</p>
                                <p>45 minutes ago</p>
                              </div>
                            </div>
                            <div class="media updates-faq-main">
                              <div class="updates-faq"><vue-feather class="font-primary" type="check"></vue-feather></div>
                              <div class="media-body updates-bottom-time">
                                <p>Ticket #43683 has been closed by <a href="javascript:void(0)">Victoria Wilson</a></p>
                                <p>Dec 7, 11:48</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
</template>