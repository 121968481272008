<template>
<div class="col-sm-12">
    <div class="card">
        <div class="card-header">
            <h5>Blockquotes</h5><span>Add a <code>&lt;footer class="blockquote-footer"&gt;</code> for identifying the source. Wrap the name of the source work in <code>&lt;cite&gt;</code>.</span>
        </div>
        <div class="card-body">
            <p class="sub-title">Naming a source</p>
            <div class="figure d-block">
                <blockquote class="blockquote">
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </blockquote>
                <figcaption class="blockquote-footer">Someone famous in
                    <cite title="Source Title">Source Title</cite>
                </figcaption>
            </div>
            <p class="sub-title">Alignment Center</p>
            <div class="figure text-center d-block">
                <blockquote class="blockquote">
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </blockquote>
                <figcaption class="blockquote-footer">Someone famous in
                    <cite title="Source Title">Source Title</cite>
                </figcaption>
            </div>
            <p class="sub-title">Alignment Right</p>
            <div class="figure text-end d-block">
                <blockquote class="blockquote">
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </blockquote>
                <figcaption class="blockquote-footer">Someone famous in
                    <cite title="Source Title">Source Title</cite>
                </figcaption>
            </div>
        </div>
    </div>
</div>
</template>
