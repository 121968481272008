<template>
<div class="col-xl-6 col-sm-12">
    <div class="btn-group btn-option" data-bs-toggle="buttons">
        <div class="btn btn-info">
            <div class="checkbox checkbox-info">
                <input id="checkbox-primary-7" type="checkbox">
                <label for="checkbox-primary-7">Option 1</label>
            </div>
        </div>
        <div class="btn btn-info">
            <div class="checkbox checkbox-info">
                <input id="checkbox-primary-8" type="checkbox">
                <label for="checkbox-primary-8">Option 2</label>
            </div>
        </div>
    </div>
</div>
<div class="col-xl-6 col-sm-12">
    <div class="btn-group btn-option" data-bs-toggle="buttons">
        <div class="btn btn-warning">
            <div class="checkbox checkbox-warning">
                <input id="checkbox-warning-9" type="checkbox">
                <label for="checkbox-warning-9">Option 1</label>
            </div>
        </div>
        <div class="btn btn-warning">
            <div class="checkbox checkbox-warning">
                <input id="checkbox-warning-10" type="checkbox">
                <label for="checkbox-warning-10">Option 2</label>
            </div>
        </div>
    </div>
</div>
<div class="col-xl-6 col-sm-12">
    <div class="btn-group btn-option" data-bs-toggle="buttons">
        <div class="btn btn-danger">
            <div class="checkbox checkbox-danger">
                <input id="checkbox-danger-11" type="checkbox">
                <label for="checkbox-danger-11">Option 1</label>
            </div>
        </div>
        <div class="btn btn-danger">
            <div class="checkbox checkbox-danger">
                <input id="checkbox-danger-12" type="checkbox">
                <label for="checkbox-danger-12">Option 2</label>
            </div>
        </div>
    </div>
</div>
</template>
