<template>
    <div class="col-md-6">
                  <div class="card">
                  <div class="card-header">
                    <h5>Sizing Progress Bars</h5><span>Use <code>.lg-progress-bar , .sm-progress-bar</code>class to change progress size to small:</span>
                  </div>
                  <div class="card-body progress-showcase row">
                    <div class="col">
                      <div class="progress lg-progress-bar">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div class="progress lg-progress-bar">
                        <div class="progress-bar bg-secondary" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div class="progress lg-progress-bar">
                        <div class="progress-bar bg-success" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div class="progress lg-progress-bar">
                        <div class="progress-bar bg-info" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
</template>