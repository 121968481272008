<template>
    <div class="card">
                    <div class="card-header">
                        <h5>Float</h5>
                    </div>
                    <div class="card-body">
                        <pre class="helper-classes">.f-left {
float: left;
}
.f-right {
float: right;
}
.f-none {
float: none;
}</pre>
                    </div>
                </div>
</template>