<template>
  <div class="col-xl-4 box-col-6">
    <div class="card bg-primary">
      <div class="card-body">
        <div class="media faq-widgets">
          <div class="media-body">
            <h2>Articles</h2>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
          </div><vue-feather type="file-text"></vue-feather>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-sm-6 box-col-6">
    <div class="card bg-primary">
      <div class="card-body">
        <div class="media faq-widgets">
          <div class="media-body">
            <h2>Knowledgebase</h2>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
          </div><vue-feather type="book-open"></vue-feather>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-sm-6 box-col-6">
    <div class="card bg-primary">
      <div class="card-body">
        <div class="media faq-widgets">
          <div class="media-body">
            <h2>Support</h2>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
          </div><vue-feather type="aperture"></vue-feather>
        </div>
      </div>
    </div>
  </div>
</template>