<template>
    <div class="row">
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-clip ribbon-primary">Ribbon</div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-clip ribbon-secondary">Ribbon</div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-clip ribbon-success">Ribbon</div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-wrapper-right card">
                  <div class="card-body">
                    <div class="ribbon ribbon-clip-right ribbon-right ribbon-primary">Ribbon</div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-wrapper-right card">
                  <div class="card-body">
                    <div class="ribbon ribbon-clip-right ribbon-right ribbon-secondary">Ribbon</div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-wrapper-right card">
                  <div class="card-body">
                    <div class="ribbon ribbon-clip-right ribbon-right ribbon-success">Ribbon</div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
            </div>
           
</template>