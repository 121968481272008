export const productlist = [
    {
        id: 1,
        image: "product-table-1.png",
        product_name: "Red Shirt",
        product_desc: "Wild West - Red Cotton Blend Regular Fit Men's Formal Shirt.",
        amount: "$10",
        stock: "In Stock",
        stockStatus:"font-success",
        start_date: "2022/4/25"
    },
    {
        id: 2,
        image: "product-table-2.png",
        product_name: "Pink Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$10",
        stock: "Out of Stock",
        stockStatus:"font-primary",
        start_date: "2022/4/25"
    },
    {
        id: 3,
        image: "product-table-3.png",
        product_name: "Gray Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$10",
        stock: "Out of Stock",
        stockStatus:"font-danger",
        start_date: "2022/4/25"
    },
    {
        id: 4,
        image: "product-table-4.png",
        product_name: "Green Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$20",
        stock: "Low Stock",
        stockStatus:"font-primary",
        start_date: "2022/4/25"
    },
    {
        id: 5,
        image: "product-table-5.png",
        product_name: "Black Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$30",
        stock: "In Stock",
        stockStatus:"font-success",
        start_date: "2022/4/25"
    },
    {
        id: 6,
        image: "product-table-6.png",
        product_name: "White Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$40",
        stock: "In Stock",
        stockStatus:"font-primary",
        start_date: "2022/4/25"
    },
    {
        id: 7,
        image: "product-table-7.png",
        product_name: "light Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$10",
        stock: "In Stock",
        stockStatus:"font-danger",
        start_date: "2022/4/25"
    },
    {
        id: 8,
        image: "product-table-5.png",
        product_name: "Gliter Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$10",
        stock: "In Stock",
        stockStatus:"font-danger",
        start_date: "2022/4/25"
    },
    {
        id: 9,
        image: "product-table-4.png",
        product_name: "Yellow Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$10",
        stock: "In Stock",
        stockStatus:"font-success",
        start_date: "2022/4/25"
    },
    {
        id: 10,
        image: "product-table-1.png",
        product_name: "Green Lipstick",
        product_desc: "Interchargebla lens Digital Camera with APS-C-X Trans CMOS Sens",
        amount: "$10",
        stock: "In Stock",
        stockStatus:"font-danger",
        start_date: "2022/4/25"
    }
]