<template>
  <head>
    <title>cuba-Home page</title>
  </head>

  <router-view></router-view>
</template>

<script>



export default {

  name: 'App',

}
</script>

