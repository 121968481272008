<template>
    <div class="col-md-12" v-if="data">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">Analysis</h4>
          <div class="card-options">
            <a class="card-options-collapse" href="#" data-bs-toggle="card-collapse">
              <i class="fe fe-chevron-up"></i>
            </a>
            <a class="card-options-remove" href="#" data-bs-toggle="card-remove">
              <i class="fe fe-x"></i>
            </a>
          </div>
        </div>
        <div class="table-responsive add-project">
          <table class="table card-table table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Title</th>
                <th>Analysis type</th>
                <th>News type</th>
                <th>Created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(analysis, index) in data" :key="index">
                <td>{{ analysis.newsTitle }}</td>
                <td>{{ analysis.type }}</td>
                <td>{{ analysis.newsType }}</td>
                <td>{{ analysis.createdAt }}</td>
                <td>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="openModal(analysis)"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
           <!-- Modal for viewing chat messages -->
    <div
      v-if="selectedAnalysis"
      class="modal fade"
      id="analysisModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="analysisModalLabel"
      aria-hidden="true"
    >
      <analysisModal :data="selectedAnalysis.analysis" />
      </div>
        </div>
      </div>
    </div>

   
  </template>

  <script>
  import { Modal } from 'bootstrap';
  import analysisModal from "./analysisModal.vue";
  
  export default {
    components: {
      analysisModal,
    },
    name: 'rooms',
    props: {
      data: {
        type: Array,
      }
    },
    data() {
      return {
        selectedAnalysis: null
      };
    },
    mounted() {
      console.log('analysis:', this.data);
    },
    methods: {
      openModal(analysis) {
        console.log('Opening modal for analysis:', analysis);
        this.selectedAnalysis = analysis;
        this.$nextTick(() => {
          const modalElement = document.getElementById('analysisModal');
          const modalInstance = new Modal(modalElement);
          modalInstance.show();
        });
      }
    }
  };
  </script>