<template>
    <div class="card" id="outline-large-button">
        <div class="card-header pb-0">
            <h3>Outline Large Buttons</h3>
            <settingPage /> <span>Add <code>.btn-outline-*</code> class for outline and <code>.btn-lg</code> class for large
                button</span>
        </div>
        <div class="card-body btn-showcase">
            <button class="btn btn-outline-primary btn-lg" type="button" title="btn btn-outline-primary btn-lg">Primary
                Button</button>
            <button class="btn btn-outline-secondary btn-lg" type="button"
                title="btn btn-outline-secondary btn-lg">Secondary Button</button>
            <button class="btn btn-outline-success btn-lg" type="button" title="btn btn-outline-success btn-lg">Success
                Button</button>
            <button class="btn btn-outline-info btn-lg" type="button" title="btn btn-outline-info btn-lg">Info
                Button</button>
            <button class="btn btn-outline-warning btn-lg" type="button" title="btn btn-outline-warning btn-lg">Warning
                Button</button>
            <button class="btn btn-outline-danger btn-lg" type="button" title="btn btn-outline-danger btn-lg">Danger
                Button</button>
            <button class="btn btn-outline-light txt-dark btn-lg" type="button" title="btn btn-outline-light btn-lg">Light
                Button</button>

        </div>
    </div>
    <div class="card" id="outline-small-button">
        <div class="card-header pb-0">
            <h3>Outline Small Buttons</h3>
            <settingPage /> <span>Add <code>.btn-outline-*</code> class for outline and <code>.btn-sm</code> class for small
                button</span>
        </div>
        <div class="card-body btn-showcase">
            <button class="btn btn-outline-primary btn-sm" type="button" title="btn btn-outline-primary btn-sm">Primary
                Button</button>
            <button class="btn btn-outline-secondary btn-sm" type="button"
                title="btn btn-outline-secondary btn-sm">Secondary Button</button>
            <button class="btn btn-outline-success btn-sm" type="button" title="btn btn-outline-success btn-sm">Success
                Button</button>
            <button class="btn btn-outline-info btn-sm" type="button" title="btn btn-outline-info btn-sm">Info
                Button</button>
            <button class="btn btn-outline-warning btn-sm" type="button" title="btn btn-outline-warning btn-sm">Warning
                Button</button>
            <button class="btn btn-outline-danger btn-sm" type="button" title="btn btn-outline-danger btn-sm">Danger
                Button</button>
            <button class="btn btn-outline-light btn-sm txt-dark" type="button" title="btn btn-outline-light btn-sm">Light
                Button</button>

        </div>
    </div>
    <div class="card" id="ex-outline-small-button">
        <div class="card-header pb-0">
            <h3>Outline Extra Small Buttons</h3>
            <settingPage /> <span>Add <code>.btn-outline-*</code> class for outline and <code>.btn-xs</code> class for extra
                small button</span>
        </div>
        <div class="card-body btn-showcase">
            <button class="btn btn-outline-primary btn-xs" type="button" title="btn btn-outline-primary btn-xs">Primary
                Button</button>
            <button class="btn btn-outline-secondary btn-xs" type="button"
                title="btn btn-outline-secondary btn-xs">Secondary Button</button>
            <button class="btn btn-outline-success btn-xs" type="button" title="btn btn-outline-success btn-xs">Success
                Button</button>
            <button class="btn btn-outline-info btn-xs" type="button" title="btn btn-outline-info btn-xs">Info
                Button</button>
            <button class="btn btn-outline-warning btn-xs" type="button" title="btn btn-outline-warning btn-xs">Warning
                Button</button>
            <button class="btn btn-outline-danger btn-xs" type="button" title="btn btn-outline-danger btn-xs">Danger
                Button</button>
            <button class="btn btn-outline-light btn-xs txt-dark" type="button" title="btn btn-outline-light btn-xs">Light
                Button</button>
        </div>
    </div>
</template>