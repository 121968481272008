export const All = [
    {
        id: 1,
        title: 'Endless admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Endless Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '12',
        resolved: '5',
        comment: '7',
        like: '10',
        progress: '70',
        class: 'badge-primary',
        width: '70%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 2,
        title: 'Universal admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Universal Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '24',
        resolved: '24',
        comment: '40',
        like: '3',
        progress: '100',
        class: 'badge-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 3,
        title: 'Poco admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Poco Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '40',
        resolved: '40',
        comment: '20',
        like: '2',
        progress: '100',
        class: 'bg-success',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 4,
        title: 'Universal admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Universal Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '24',
        resolved: '24',
        comment: '40',
        like: '3',
        progress: '100',
        class: 'badge-info',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 5,
        title: 'Endless admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Endless Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '12',
        resolved: '5',
        comment: '7',
        like: '10',
        progress: '70',
        class: 'badge-warning',
        width: '70%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 6,
        title: 'Poco admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Poco Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '40',
        resolved: '40',
        comment: '20',
        like: '2',
        progress: '100',
        class: 'badge-danger',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
        
    },
];

export const Doing = [
    {
        id: 1,
        title: 'Endless admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Endless Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '12',
        resolved: '5',
        comment: '7',
        like: '+10 More',
        progress: '70',
        class: 'badge-primary',
        width: '70%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 2,
        title: 'Universal admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Universal Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '24',
        resolved: '24',
        comment: '40',
        like: '+3 More',
        progress: '100',
        class: 'bg-primary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 3,
        title: 'Poco admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Poco Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '40',
        resolved: '40',
        comment: '20',
        like: '+2 More',
        progress: '100',
        class: 'bg-primary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 4,
        title: 'Universal admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Universal Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '24',
        resolved: '24',
        comment: '40',
        like: '+3 More',
        progress: '100',
        class: 'bg-primary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 5,
        title: 'Endless admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Endless Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '12',
        resolved: '5',
        comment: '7',
        like: '+10 More',
        progress: '70',
        class: 'badge-primary',
        width: '70%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 6,
        title: 'Poco admin Design',
        badge: 'Doing',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Poco Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '40',
        resolved: '40',
        comment: '20',
        like: '+2 More',
        progress: '100',
        class: 'bg-primary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
];


export const Done = [
    {
        id: 1,
        title: 'Endless admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Endless Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '12',
        resolved: '5',
        comment: '7',
        like: '+10 More',
        progress: '70',
        class: 'badge-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 2,
        title: 'Universal admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Universal Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '24',
        resolved: '24',
        comment: '40',
        like: '+3 More',
        progress: '100',
        class: 'bg-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 3,
        title: 'Poco admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Poco Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '40',
        resolved: '40',
        comment: '20',
        like: '+2 More',
        progress: '100',
        class: 'bg-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 4,
        title: 'Universal admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Universal Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '24',
        resolved: '24',
        comment: '40',
        like: '+3 More',
        progress: '100',
        class: 'bg-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 5,
        title: 'Endless admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Endless Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '12',
        resolved: '5',
        comment: '7',
        like: '+10 More',
        progress: '70',
        class: 'badge-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
    {
        id: 6,
        title: 'Poco admin Design',
        badge: 'Done',
        img: require("../../assets/images/user/3.jpg"),
        sites: 'Themeforest, australia',
        desc: 'Poco Admin is a full featured, multipurpose, premium bootstrap admin template.',
        issue: '40',
        resolved: '40',
        comment: '20',
        like: '+2 More',
        progress: '100',
        class: 'bg-secondary',
        width: '100%' ,
        customers_img1: require("../../assets/images/user/3.jpg"),
        customers_img2: require("../../assets/images/user/5.jpg"),
        customers_img3: require("../../assets/images/user/1.jpg"),
    },
];