<template>
<div class="col-xxl-4 col-md-6 col-sm-12">
    <div class="btn-radio">
        <div class="btn-group" data-bs-toggle="buttons">
            <div class="btn btn-info">
                <div class="radio radio-info">
                    <input id="radio15" type="radio" name="radio4" value="option1">
                    <label for="radio15">Option 1</label>
                </div>
            </div>
            <div class="btn btn-info active">
                <div class="radio radio-info">
                    <input id="radio16" type="radio" name="radio4" value="option1" checked>
                    <label for="radio16">Option 2</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-xxl-4 col-md-6 col-sm-12">
    <div class="btn-radio">
        <div class="btn-group" data-bs-toggle="buttons">
            <div class="btn btn-warning">
                <div class="radio radio-warning">
                    <input id="radio17" type="radio" name="radio5" value="option1">
                    <label for="radio17">Option 1</label>
                </div>
            </div>
            <div class="btn btn-warning active">
                <div class="radio radio-warning">
                    <input id="radio18" type="radio" name="radio5" value="option1" checked>
                    <label for="radio18">Option 2</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-xxl-4 col-md-6 col-sm-12">
    <div class="btn-radio">
        <div class="btn-group" data-bs-toggle="buttons">
            <div class="btn btn-danger">
                <div class="radio radio-danger">
                    <input id="radio20" type="radio" name="radio6" value="option1">
                    <label for="radio20">Option 1</label>
                </div>
            </div>
            <div class="btn btn-danger active">
                <div class="radio radio-danger">
                    <input id="radio22" type="radio" name="radio6" value="option1" checked>
                    <label for="radio22">Option 2</label>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
