<template>
    <div class="col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Margins</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.m-0 {
                        margin: 0px !important;
                        }
                        .m-5 {
                        margin: 5px !important;
                        }
                        .m-10 {
                        margin: 10px !important;
                        }
                        .m-15 {
                        margin: 15px !important;
                        }
                        .m-20 {
                        margin: 20px !important;
                        }
                        .m-25 {
                        margin: 25px !important;
                        }
                        .m-30 {
                        margin: 30px !important;
                        }
                        .m-35 {
                        margin: 35px !important;
                        }
                        .m-40 {
                        margin: 40px !important;
                        }
                        .m-45 {
                        margin: 45px !important;
                        }
                        .m-50 {
                        margin: 50px !important;
                        }</pre>
                  </div>
                </div>
          </div>
</template>