import { auth } from '../../config/firebaseConfig'; // Import Firebase auth
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getUserToken } from '../../utils/authHelpers'; // Adjust the import path as necessary
import axios from 'axios';

const state = {
  user: null, // Add user state
  authError: null, // Add authentication error state
  token: null // Add token state
};

const getters = {
  user: (state) => state.user, // Add user getter
  authError: (state) => state.authError, // Add authError getter
  token: (state) => state.token // Add token getter
};

const mutations = {
  setUser: (state, user) => { // Add setUser mutation
    state.user = user;
  },
  setAuthError: (state, error) => { // Add setAuthError mutation
    state.authError = error;
  },
  setToken: (state, token) => { // Add setToken mutation
    state.token = token;
  }
};

const actions = {
  signUp: async ({ commit }, { email, password }) => { // Add signUp action
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      commit('setUser', userCredential.user);
      const token = await getUserToken();
      commit('setToken', token);
    } catch (error) {
      commit('setAuthError', error.message);
    }
  },
  signIn: async ({ commit }, { email, password }) => { // Add signIn action
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      commit('setUser', userCredential.user);
      const token = await getUserToken();
      commit('setToken', token);
      localStorage.setItem('User', JSON.stringify({ email: userCredential.user.email, user: true }));
    } catch (error) {
      commit('setAuthError', error.message);
    }
  },
  signOut: async ({ commit }) => { // Add signOut action
    try {
      await signOut(auth);
      commit('setUser', null);
      commit('setToken', null);
    } catch (error) {
      commit('setAuthError', error.message);
    }
  },
  fetchToken: async ({ commit }) => { // Add fetchToken action
    try {
      const token = await getUserToken();
      commit('setToken', token);
    } catch (error) {
      commit('setAuthError', error.message);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};