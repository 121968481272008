<template>
    <div class="col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Margin Bottom</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.m-b-0 {
margin-bottom: 0px !important;
}
.m-b-5 {
margin-bottom: 5px !important;
}
.m-b-10 {
margin-bottom: 10px !important;
}
.m-b-15 {
margin-bottom: 15px !important;
}
.m-b-20 {
margin-bottom: 20px !important;
}
.m-b-25 {
margin-bottom: 25px !important;
}
.m-b-30 {
margin-bottom: 30px !important;
}
.m-b-35 {
margin-bottom: 35px !important;
}
.m-b-40 {
margin-bottom: 40px !important;
}
.m-b-45 {
margin-bottom: 45px !important;
}
.m-b-50 {
margin-bottom: 50px !important;
}</pre>
                  </div>
                </div>
              </div>
</template>