<template>

<div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
    <div class="row">
        <div class="col-xl-12">
            <div class="card widget-1">
                <div class="card-body">
                    <div class="widget-content">
                        <div class="widget-round secondary">
                            <div class="bg-round">
                                <svg class="svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#stroke-user`"></use>
                                </svg>
                                <svg class="half-circle svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h4>{{users?.total}}</h4>
                            <span class="f-light">
                                Users 
                            </span>
                        </div> 
                    </div>
                    <div class="font-success f-w-500">
                        <i :class="getDeltaIcon(users?.todayDelta)"></i><span>{{ users?.todayDelta }} </span> 
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card widget-1">
                <div class="card-body">
                    <div class="widget-content">
                        <div class="widget-round primary">
                            <div class="bg-round">
                                <svg class="svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#stroke-chat`"></use>
                                </svg>
                                <svg class="half-circle svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h4>{{messages?.total}}</h4>
                            <span class="f-light">
                                Messages 
                            </span>
                        </div> 
                    </div>
                    <div class="font-success f-w-500">
                        <i :class="getDeltaIcon(messages?.todayDelta)"></i><span>{{ messages?.todayDelta }} </span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
    <div class="row">
        <div class="col-xl-12" >
            <div class="card widget-1">
                <div class="card-body">
                    <div class="widget-content">
                        <div class="widget-round secondary">
                            <div class="bg-round">
                                <svg class="svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#stroke-task`"></use>
                                </svg>
                                <svg class="half-circle svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h4>{{signals?.total}}</h4>
                            <span class="f-light">
                                Signals
                            </span>
                        </div>
                    </div>
                    <div class="font-success f-w-500">
                        <i :class="getDeltaIcon(signals?.todayDelta)"></i><span>{{ signals?.todayDelta }} </span> 
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12" >
            <div class="card widget-1">
                <div class="card-body">
                    <div class="widget-content">
                        <div class="widget-round secondary">
                            <div class="bg-round">
                                <svg class="svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#stroke-task`"></use>
                                </svg>
                                <svg class="half-circle svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h4>{{watchers?.total}}</h4>
                            <span class="f-light">
                                Watchers
                            </span>
                        </div>
                    </div>
                    <div class="font-success f-w-500">
                        <i :class="getDeltaIcon(watchers?.todayDelta)"></i><span>{{ watchers?.todayDelta }} </span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
    <div class="row">
        <div class="col-xl-12" >
            <div class="card widget-1">
                <div class="card-body">
                    <div class="widget-content">
                        <div class="widget-round secondary">
                            <div class="bg-round">
                                <svg class="svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#stroke-task`"></use>
                                </svg>
                                <svg class="half-circle svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h4>{{analysis?.news.total}}</h4>
                            <span class="f-light">
                                News Analysis
                            </span>
                        </div>
                    </div>
                    <div class="font-success f-w-500">
                        <i :class="getDeltaIcon(analysis?.news?.todayDelta)"></i><span>{{ analysis?.news?.todayDelta }} </span> 
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12" >
            <div class="card widget-1">
                <div class="card-body">
                    <div class="widget-content">
                        <div class="widget-round secondary">
                            <div class="bg-round">
                                <svg class="svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#stroke-task`"></use>
                                </svg>
                                <svg class="half-circle svg-fill">
                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <h4>{{analysis?.events.total}}</h4>
                            <span class="f-light">
                                Events Analysis
                            </span>
                        </div>
                    </div>
                    <div class="font-success f-w-500">
                        <i :class="getDeltaIcon(analysis?.events?.todayDelta)"></i><span>{{ analysis?.events?.todayDelta }} </span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import{mapState, mapActions} from 'vuex'

export default {
    data() {
        return {
            // users: {
            //     total: 1,
            //     deltaToday: 3,
            // },
            
        }
    },
  computed: {
    ...mapState({
        users: (state) => state.dashboard.users,
        signals: (state) => state.dashboard.signals,
        messages: (state) => state.dashboard.messages,
        watchers: (state) => state.dashboard.watchers,
        analysis: (state) => state.dashboard.analysis,
    }),

  },
  methods: {

    ...mapActions('dashboard', ['fetchDashboardData']),
    getFormattedValue(value) {
        console.log(value);
        if (value > 0) {
            return `+${value}`;
        }
        return;
    },
    getDeltaIcon(delta) {
        if (delta > 0) {
            return 'icon-arrow-up icon-rotate me-1';
        }
        return 'icon-minus me-1';
    },
  },
  created() {
    this.fetchDashboardData();
    console.log(this.users)
  },
};

</script>
