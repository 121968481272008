<template>
    <div class="col-xl-6">
                <div class="card">
                  <div class="card-header">
                    <h5>Ratio</h5>
                  </div>
                  <div class="card-body avatar-showcase">
                    <div class="avatars">
                      <div class="avatar ratio"><img class="b-r-8 img-100" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar ratio"><img class="b-r-8 img-90" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar ratio"><img class="b-r-8 img-80" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar ratio"><img class="b-r-8 img-70" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar ratio"><img class="b-r-8 img-60" src="@/assets/images/user/1.jpg" alt="#"></div>
                      <div class="avatar ratio"><img class="b-r-8 img-50" src="@/assets/images/user/1.jpg" alt="#"></div>
                    </div>
                  </div>
                </div>
              </div>
</template>