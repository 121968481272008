<template>
    <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="text-white">Primary Color Card</h5>
          </div>
          <div class="card-body bg-primary">
            <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
          </div>
          <div class="card-footer bg-primary">
            <h6 class="mb-0">Card Footer</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header bg-secondary">
            <h5 class="text-white">Secondary Color Card</h5>
          </div>
          <div class="card-body bg-secondary">
            <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
          </div>
          <div class="card-footer bg-secondary">
            <h6 class="mb-0 text-white">Card Footer</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header bg-success">
            <h5 class="text-white">Success color Card</h5>
          </div>
          <div class="card-body bg-success">
            <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
          </div>
          <div class="card-footer bg-success">
            <h6 class="mb-0">Card Footer</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="text-white">Primary Color Header</h5>
          </div>
          <div class="card-body">
            <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
          </div>
          <div class="card-footer">
            <h6 class="mb-0">Card Footer</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header">
            <h5>Primary Color Body</h5>
          </div>
          <div class="card-body bg-primary">
            <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
          </div>
          <div class="card-footer">
            <h6 class="mb-0">Card Footer</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header">
            <h5>Primary Color Footer</h5>
          </div>
          <div class="card-body">
            <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
          </div>
          <div class="card-footer bg-primary">
            <h6 class="mb-0">Card Footer</h6>
          </div>
        </div>
      </div>
</template>