<template>
  <div class="col-sm-12 col-md-6 text-start">
    <div class="card">
      <div class="card-header ">
        <h5>Prefetch</h5><span>Prefetched data is fetched and processed on initialization</span>
      </div>
      <div class="card-body">
        <div id="prefetch">
          <form class="theme-form">
            <div class="form-group">
              <input class="form-control typeahead form-control" type="text" placeholder="Countries">
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
</template>