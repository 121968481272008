<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5>Colored breadcrumb</h5><span>use class <code>.breadcrumb-colored .bg-primary</code></span>
      </div>
      <div class="card-body">
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-primary">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-secondary">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-success">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-info">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-warning">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-danger">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-30 bg-light">
          <li class="breadcrumb-item"><a class="txt-dark" href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item txt-dark active">Library</li>
        </ol>
        <ol class="breadcrumb breadcrumb-colored m-b-0 bg-dark">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
          <li class="breadcrumb-item active">Library</li>
        </ol>
      </div>
    </div>
  </div>
</template>