<template>
    <div class="row">
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-vertical-left-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-bookmark ribbon-vertical-left ribbon-primary"><i class="icofont icofont-love"></i></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-vertical-left-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary"><i class="icon-gift"></i></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-vertical-left-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-bookmark ribbon-vertical-left ribbon-success"><i class="icon-signal"></i></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-vertical-right-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-primary"><i class="icofont icofont-love"></i></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-vertical-right-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-secondary"><i class="icon-gift"></i></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-14 col-md-6 col-lg-4">
                <div class="ribbon-vertical-right-wrapper card">
                  <div class="card-body">
                    <div class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-success"><i class="icon-signal"></i></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  </div>
                </div>
              </div>
            </div>
</template>