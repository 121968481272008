<template>
    <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Groups</h5>
                  </div>
                  <div class="card-body avatar-showcase">
                    <div class="customers d-inline-block avatar-group">
                      <ul>
                        <li class="d-inline-block"><img class="img-70 rounded-circle" src="@/assets/images/user/3.jpg" alt=""></li>
                        <li class="d-inline-block"><img class="img-70 rounded-circle" src="@/assets/images/user/5.jpg" alt=""></li>
                        <li class="d-inline-block"><img class="img-70 rounded-circle" src="@/assets/images/user/1.jpg" alt=""></li>
                      </ul>
                    </div>
                    <div class="customers d-inline-block avatar-group">
                      <ul>
                        <li class="d-inline-block"><img class="img-50 rounded-circle" src="@/assets/images/user/3.jpg" alt=""></li>
                        <li class="d-inline-block"><img class="img-50 rounded-circle" src="@/assets/images/user/5.jpg" alt=""></li>
                        <li class="d-inline-block"><img class="img-50 rounded-circle" src="@/assets/images/user/1.jpg" alt=""></li>
                      </ul>
                    </div>
                    <div class="customers d-inline-block avatar-group">
                      <ul>
                        <li class="d-inline-block"><img class="img-40 rounded-circle" src="@/assets/images/user/3.jpg" alt=""></li>
                        <li class="d-inline-block"><img class="img-40 rounded-circle" src="@/assets/images/user/5.jpg" alt=""></li>
                        <li class="d-inline-block"><img class="img-40 rounded-circle" src="@/assets/images/user/1.jpg" alt=""></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
</template>