<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Outline Color Variant</h5>
      </div>
      <div class="card-body">
        <div class="mb-2">
          <div class="col-form-label">Primary Select</div>
          <select class="form-select form-control-primary" name="select">
            <option value="opt1">Select One Value Only</option>
            <option value="opt2">Type 2</option>
            <option value="opt3">Type 3</option>
            <option value="opt4">Type 4</option>
            <option value="opt5">Type 5</option>
            <option value="opt6">Type 6</option>
            <option value="opt7">Type 7</option>
            <option value="opt8">Type 8</option>
          </select>
        </div>
        <div class="mb-2">
          <div class="col-form-label">Secondary Select</div>
          <select class="form-select form-control-secondary" name="select">
            <option value="opt1">Select One Value Only</option>
            <option value="opt2">Type 2</option>
            <option value="opt3">Type 3</option>
            <option value="opt4">Type 4</option>
            <option value="opt5">Type 5</option>
            <option value="opt6">Type 6</option>
            <option value="opt7">Type 7</option>
            <option value="opt8">Type 8</option>
          </select>
        </div>
        <div class="mb-2">
          <div class="col-form-label">Success Select</div>
          <select class="form-select form-control-success" name="select">
            <option value="opt1">Select One Value Only</option>
            <option value="opt2">Type 2</option>
            <option value="opt3">Type 3</option>
            <option value="opt4">Type 4</option>
            <option value="opt5">Type 5</option>
            <option value="opt6">Type 6</option>
            <option value="opt7">Type 7</option>
            <option value="opt8">Type 8</option>
          </select>
        </div>
        <div class="mb-2">
          <div class="col-form-label">Info Select</div>
          <select class="form-select form-control-info" name="select">
            <option value="opt1">Select One Value Only</option>
            <option value="opt2">Type 2</option>
            <option value="opt3">Type 3</option>
            <option value="opt4">Type 4</option>
            <option value="opt5">Type 5</option>
            <option value="opt6">Type 6</option>
            <option value="opt7">Type 7</option>
            <option value="opt8">Type 8</option>
          </select>
        </div>
        <div class="mb-2">
          <div class="col-form-label">Warning Select</div>
          <select class="form-select form-control-warning" name="select">
            <option value="opt1">Select One Value Only</option>
            <option value="opt2">Type 2</option>
            <option value="opt3">Type 3</option>
            <option value="opt4">Type 4</option>
            <option value="opt5">Type 5</option>
            <option value="opt6">Type 6</option>
            <option value="opt7">Type 7</option>
            <option value="opt8">Type 8</option>
          </select>
        </div>
        <div class="mb-2">
          <div class="col-form-label">Danger Select</div>
          <select class="form-select form-control-danger" name="select">
            <option value="opt1">Select One Value Only</option>
            <option value="opt2">Type 2</option>
            <option value="opt3">Type 3</option>
            <option value="opt4">Type 4</option>
            <option value="opt5">Type 5</option>
            <option value="opt6">Type 6</option>
            <option value="opt7">Type 7</option>
            <option value="opt8">Type 8</option>
          </select>
        </div>
        <div>
          <outlineInverse />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import outlineInverse from "./outlineInverse.vue"
export default {
  components: {
    outlineInverse
  }
}
</script>