<template>
<div class="col-md-6 col-sm-12">
    <div class="card">
        <div class="card-header">
            <h5>All Borders Color</h5>
        </div>
        <div class="card-body">
            <pre class="helper-classes">.b-primary {
border: 1px solid #7366ff !important;
}
.b-t-primary {
border-top: 1px solid #7366ff !important;
}
.b-b-primary {
border-bottom: 1px solid #7366ff !important;
}
.b-l-primary {
border-left: 1px solid #7366ff !important;
}
.b-r-primary {
border-right: 1px solid #7366ff !important;
}
.b-secondary {
border: 1px solid #f73164 !important;
}
.b-t-secondary {
border-top: 1px solid #f73164 !important;
}
.b-b-secondary {
border-bottom: 1px solid #f73164 !important;
}
.b-l-secondary {
border-left: 1px solid #f73164 !important;
}
.b-r-secondary {
border-right: 1px solid #f73164 !important;
}
.b-success {
border: 1px solid #51bb25 !important;
}
.b-t-success {
border-top: 1px solid #51bb25 !important;
}
.b-b-success {
border-bottom: 1px solid #51bb25 !important;
}
.b-l-success {
border-left: 1px solid #51bb25 !important;
}
.b-r-success {
border-right: 1px solid #51bb25 !important;
}
.b-danger {
border: 1px solid #dc3545 !important;
}
.b-t-danger {
border-top: 1px solid #dc3545 !important;
}
.b-b-danger {
border-bottom: 1px solid #dc3545 !important;
}
.b-l-danger {
border-left: 1px solid #dc3545 !important;
}
.b-r-danger {
border-right: 1px solid #dc3545 !important;
}
.b-info {
border: 1px solid #a927f9 !important;
}
.b-t-info {
border-top: 1px solid #a927f9 !important;
}
.b-b-info {
border-bottom: 1px solid #a927f9 !important;
}
.b-l-info {
border-left: 1px solid #a927f9 !important;
}
.b-r-info {
border-right: 1px solid #a927f9 !important;
}
.b-light {
border: 1px solid #f4f4f4 !important;
}
.b-t-light {
border-top: 1px solid #f4f4f4 !important;
}
.b-b-light {
border-bottom: 1px solid #f4f4f4 !important;
}
.b-l-light {
border-left: 1px solid #f4f4f4 !important;
}
.b-r-light {
border-right: 1px solid #f4f4f4 !important;
}
.b-dark {
border: 1px solid #2c323f !important;
}
.b-t-dark {
border-top: 1px solid #2c323f !important;
}
.b-b-dark {
border-bottom: 1px solid #2c323f !important;
}
.b-l-dark {
border-left: 1px solid #2c323f !important;
}
.b-r-dark {
border-right: 1px solid #2c323f !important;
}
.b-warning {
border: 1px solid #f8d62b !important;
}
.b-t-warning {
border-top: 1px solid #f8d62b !important;
}
.b-b-warning {
border-bottom: 1px solid #f8d62b !important;
}
.b-l-warning {
border-left: 1px solid #f8d62b !important;
}
.b-r-warning {
border-right: 1px solid #f8d62b !important;
}</pre>
        </div>
    </div>
</div>
</template>
