import { getUsers, getUser, createUser, updateUser, deleteUser, getUsersGroupsByPromoCode} from '../../api/users';

const state = {
  users: [],
  user: null,
groupsByPromoCode: []
};

const getters = {
  users: (state) => state.users,
  userProfile: (state) => state.user,
  groupsByPromoCode: (state) => state.groupsByPromoCode
};

const mutations = {
  setUsers:(state,payload)=>{
    state.users = payload;
  },
  setUser:(state,payload)=>{
    state.user = payload;
  },
  clearUser: (state,payload)=>{
    state.user = payload;
  },
  setGroupsByPromoCode:(state,payload)=>{
    state.groupsByPromoCode = payload;
  }
};

const actions = {
   async fetchUsers({ commit }) {
    try {
      const response = await getUsers();
      commit('setUsers', response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  },

  async fetchGroupsByPromoCode({ commit }) {
    try {
      const response = await getUsersGroupsByPromoCode();
      commit('setGroupsByPromoCode', response.data)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  },

  async fetchUser({ commit }, id) {
    try {
      console.log(id);
      const response = await getUser(id);
      commit('setUser', response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  },

  async addUser({ commit }, userData) {
    try {
      const response = await createUser(userData);
      commit('SET_USER', response.data);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  },

  async updateUser({ commit }, { id, userData }) {
    try {
      const response = await updateUser(id, userData);
      commit('SET_USER', response.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  },

  async deleteUser({ commit }, id) {
    try {
      await deleteUser(id);
      commit('CLEAR_USER');
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};