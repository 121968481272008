export const pricing1 = [
        {
            title:"Standard",
            price:10,
             list:[{content:"50GB Disk Space"},
             {content:"50 Email Accounts"},
             {content:"Maintenance"},
             {content:"15 Subdomains"}]
        },
        {
            title:"PREMIUM",
            price:20,
             list:[{content:"10% on all product"},
             {content:"50 Email Accounts"},
             {content:"Maintenance"},
             {content:"15 Subdomains"}]
        },
        {
            title:"Standard",
            price:50,
             list:[{content:"Upload 50 product"},
             {content:"50 Email Accounts"},
             {content:"Maintenance"},
             {content:"15 Subdomains"}]
        },
        {
            title:"AUTHER PACK",
            price:50,
             list:[{content:"Upload 50 product"},
             {content:"50 Email Accounts"},
             {content:"Maintenance"},
             {content:"15 Subdomains"}]
        }
    ]
export const pricing2 = 
    [
        {
            title: "Standard",
            price: 15,
            "text": "Standard Price"
        },
        {
            title: "Buisness",
            price: 25,
            "text": "Buisness Price"
        },
        {
            title: "Premium",
            price: 35,
            "text": "Premium Price"
        },
        {
            title: "Extra",
            price: 45,
            "text": "Extra Price"
        }
    ]
