<template>
<div class="col-sm-12 col-lg-6">
    <div class="card height-equal">
        <div class="card-header">
            <h5>Dismissing</h5><span>To animate alerts when dismissing them, be sure to add the .fade and .show classes.</span>
        </div>
        <div class="card-body">
            <div class="alert alert-primary dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-secondary dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-success dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-info dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-warning dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-light dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-dark dark alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
    </div>
</div>
</template>
