<template>
  <div class="row">
    <div class="col-xxl-8 col-xl-6">
      <h6 class="mb-2">Search result for "Pixelstrap"</h6>
      <div class="info-block"><a href="">https://themeforest.net/user/pixelstrap/portfolio/</a>
        <h6>PixelStrap - Portfolio | ThemeForest</h6>
        <p>2020's Best Selling Creative WP Themes. The #1 Source of Premium WP Themes! ThemeForest 45,000+ WP Themes &
          Website Templates From $2. Check it Out! </p>
        <div class="star-ratings">
          <ul class="search-info">
            <li>3 stars</li>
            <li>590 votes</li>
            <li>Theme</li>
          </ul>
        </div>
      </div>
      <div class="info-block"><a href="">PixelStrap - Portfolio | ThemeForestthemeforest.net › user › </a>
        <h6>PixelStrap - Portfolio | ThemeForest</h6>
        <p>The #1 marketplace for premium website templates, including themes for WordPress, Magento, Drupal, Joomla, and
          more. Create a website, fast.</p>
        <div class="star-ratings">
          <ul class="search-info">
            <li>3 stars</li>
            <li>590 votes</li>
            <li>Theme</li>
          </ul>
        </div>
      </div>
      <div class="info-block"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
        <h6>Morbi feugiat mauris vel semper fringilla.</h6>
        <p>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
        <div class="star-ratings">
          <ul class="search-info">
            <li><i class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rating"></i><i
                class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rating"></i><i
                class="icofont icofont-ui-rate-blank"></i></li>
            <li>4 stars</li>
            <li>590 votes</li>
            <li>Theme</li>
          </ul>
        </div>
      </div>
      <div class="info-block"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
        <h6>Morbi feugiat mauris vel semper fringilla.</h6>
        <p>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
        <div class="star-ratings">
          <ul class="search-info">
            <li><i class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rating"></i><i
                class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rating"></i><i
                class="icofont icofont-ui-rating"></i></li>
            <li>5 stars</li>
            <li>590 votes</li>
            <li>Theme</li>
          </ul>
        </div>
      </div>
    </div>
    <blogShadow />
  </div>
</template>
<script >
import blogShadow from "./blogShadow.vue"
export default {
  components: {
    blogShadow
  }
}
</script>