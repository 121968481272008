<template>
    <div class="col-sm-12 col-xl-12">
              <div class="card">
                <div class="card-header">
                  <h5>With badges</h5>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">Cras justo odio<span class="badge badge-primary counter">14</span></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">Dapibus ac facilisis in<span class="badge badge-secondary counter">2</span></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">Morbi leo risus<span class="badge badge-success rounded-pill counter">1</span></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">Dapibus ac facilisis in<span class="badge badge-info rounded-pill">100%</span></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">Dapibus ac facilisis in<span class="badge badge-warning counter">2</span></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">Morbi leo risus<span class="badge badge-danger rounded-pill">sale</span></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">Cras justo odio<span class="badge badge-light text-dark counter">14</span></li>
                  </ul>
                </div>
              </div>
            </div>
</template>