<template>
    <div class="card">
                  <div class="card-header">
                    <h5>Position</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.p-static {
position: static;
}
.p-absolute {
position: absolute;
}
.p-fixed {
position: fixed;
}
.p-relative {
position: relative;
}
.p-initial {
position: initial;
}
.p-inherit {
position: inherit;
}</pre>
                  </div>
                </div>
</template>