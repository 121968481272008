<template>
<div class="col-sm-12" v-if="user">
    <div class="card hovercard text-center">
        <div class="info">
            <div class="row" data-intro="This is the your details" id="info-bar-tour">
                <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ttl-info text-start">
                                <h6><i class="fa fa-envelope"></i>   Email</h6><span>{{ user.email }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ttl-info text-start ttl-sm-mb-0">
                                <h6><i class="fa fa-calendar"></i>   Registered At</h6><span>{{user.createdAt}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                    <div class="user-designation">
                        <div class="title"><a target="_blank" href="">{{user.firstName}} {{user.lastName}}</a></div>
                        <div class="desc mt-2">{{ user.platform }}</div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ttl-info text-start ttl-xs-mt">
                                <h6><i class="fa fa-phone"></i>   Phone</h6><span>{{ user.phone }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ttl-info text-start ttl-sm-mb-0">
                                <h6><i class="fa fa-location-arrow"></i>   Language</h6><span>{{ user.language }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'personalInfo',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
    data() {
        return {
        messageCount: 0,
        analysisCount: 0
        };
    },
    mounted() {
        let totalMessages = 0;
        this.user?.messages?.forEach(room => {
            totalMessages += room.messages?.length;
        });

        this.messageCount = totalMessages;
    }
};
</script>