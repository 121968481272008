<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Copy HTML</h4> <button type="button" class="btn-close"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body ui-front">
                <grammarly-extension data-grammarly-shadow-root="true"
                    style="position: absolute; top: 0px; left: 0px; pointer-events: none;"
                    class="cGcvT"></grammarly-extension>
                <grammarly-extension data-grammarly-shadow-root="true"
                    style="mix-blend-mode: darken; position: absolute; top: 0px; left: 0px; pointer-events: none;"
                    class="cGcvT"></grammarly-extension> <textarea class="form-control btn-square"
                    style="min-height: 200px; margin-bottom: 10px;		font-family: Monaco, Fixed" spellcheck="true">&lt;div class="row form-builder-2"&gt;
                                    &lt;div class="col-md-12"&gt;
                                        &lt;div class="mb-3 ui-draggable-handle" style="position: static;"&gt;
                                            &lt;label for="input-text-1"&gt;Text Input&lt;/label&gt;
                                            &lt;input class="form-control btn-square" id="input-text-1" type="email" placeholder="Enter email"&gt;
                                            &lt;p class="help-block"&gt;Example block-level help text here.&lt;/p&gt;
                                        &lt;/div&gt;
                                    &lt;/div&gt;
                                &lt;/div&gt;</textarea>
            </div>
        </div>
    </div>
</template>

<script>
import {
    VueDraggableNext
} from 'vue-draggable-next'

export default {
    data() {
        return {
            display: false,
            show: 1,
            text: '',

        }
    },
    components: {
        draggable: VueDraggableNext,
    },
    methods: {
        change(e) {
            this.show = e.target.value
        },
        finish(e) {
            var a = document.getElementById(e.item.id)
            if (document.getElementById(e.item) == null) {
                a[0].append(document.getElementById('add').outerHTML)

            }
            if (document.getElementById('formhtml') === null) {
                this.text = ''
            } else {
                document.getElementById('formhtml').innerHTML = document.getElementById('formhtml').outerHTML
                this.text = document.getElementById('formhtml').innerHTML
            }
        },

    }
}
</script>