<template>
<div class="col-sm-12">
    <div class="card">
        <div class="card-header">
            <h5>Headings</h5><span>All HTML headings, <code>&lt;h1&gt;</code> through <code>&lt;h6&gt;</code>, are available.</span>
        </div>
        <div class="card-body typography">
            <div class="row">
                <div class="col-md-6">
                    <h1>This is a Heading 1</h1>
                    <h2>This is a Heading 2</h2>
                    <h3>This is a Heading 3</h3>
                    <h4>This is a Heading 4</h4>
                    <h5>This is a Heading 5</h5>
                    <h6>This is a Heading 6</h6>
                </div>
                <div class="col-md-6">
                    <p class="h1 txt-primary">Heading 1<small>Sub Heading</small></p>
                    <p class="h2 txt-secondary">Heading 2<small>Sub Heading</small></p>
                    <p class="h3 txt-success">Heading 3<small>Sub Heading</small></p>
                    <p class="h4 txt-info">Heading 4<small>Sub Heading</small></p>
                    <p class="h5 txt-warning">Heading 5<small>Sub Heading</small></p>
                    <p class="h6 txt-danger">Heading 6<small>Sub Heading</small></p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
