<template>
    <div class="col-sm-12 col-md-6">
                <div class="card height-equal">
                    <div class="card-header">
                        <h5>Badges as part buttons</h5>
                    </div>
                    <div class="card-body">
                        <div class="mb-3">
                            <button class="btn btn-primary" type="button">Messages <span class="badge rounded-pill badge-light text-dark p-2">
                                    <i data-feather="mail">
                                        <vue-feather type="mail"></vue-feather>
                                    </i></span></button>
                        </div>
                        <div class="mb-3">
                            <button class="btn btn-secondary" type="button">notifications <span class="badge rounded-pill badge-light text-dark p-2">
                                    <i data-feather="bell">
                                        <vue-feather type="bell"></vue-feather>
                                    </i></span></button>
                        </div>
                        <div class="mb-3">
                            <button class="btn btn-success" type="button">Update available <span class="badge rounded-pill badge-light text-dark p-2">
                                    <i data-feather="settings">
                                        <vue-feather type="settings"></vue-feather>
                                    </i></span></button>
                        </div>
                        <div class="mb-3">
                            <button class="btn btn-info" type="button">Playing Now <span class="badge rounded-pill badge-light text-dark p-2">
                                    <i data-feather="music">
                                        <vue-feather type="music"></vue-feather>
                                    </i></span></button>
                        </div>
                        <div class="mb-3">
                            <button class="btn btn-warning" type="button">1.2 GB Used <span class="badge rounded-pill badge-light text-dark p-2">
                                    <i data-feather="alert-triangle">
                                        <vue-feather type="alert-triangle"></vue-feather>
                                    </i></span></button>
                        </div>
                        <div>
                            <button class="btn btn-danger" type="button">Alert <span class="badge rounded-pill badge-light text-dark p-2">
                                    <i data-feather="alert-circle">
                                        <vue-feather type="alert-circle"></vue-feather>
                                    </i></span></button>
                        </div>
                    </div>
                </div>
            </div>
</template>