<template>
    <div class="col-xxl-4 col-xl-6 mt-4">
        <div class="card o-hidden">
            <div class="blog-box blog-shadow"><img class="img-fluid" src="@/assets/images/blog/blog.jpg" alt="">
                <div class="blog-details">
                    <p>25 July 2018</p>
                    <h4>Accusamus et iusto odio dignissimos ducimus qui blanditiis.</h4>
                    <ul class="blog-social">
                        <li><i class="icofont icofont-user"></i>Mark Jecno</li>
                        <li><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="info-block"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
            <h6>Pixelstrap Website Templates from ThemeForest </h6>
            <p>Get 59 pixelstrap website templates on ThemeForest. Buy pixelstrap website templates from $7. All created by
                our Global Community of independent Web ...</p>
            <div class="star-ratings">
                <ul class="search-info">
                    <li><i class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rating"></i><i
                            class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rate-blank"></i><i
                            class="icofont icofont-ui-rate-blank"></i></li>
                    <li>3 stars</li>
                    <li>590 votes</li>
                    <li>Theme</li>
                </ul>
            </div>
        </div>
        <div class="info-block"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
            <h6>Morbi feugiat mauris vel semper fringilla.</h6>
            <p>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
            <div class="star-ratings">
                <ul class="search-info">
                    <li><i class="icofont icofont-ui-rating"></i><i class="icofont icofont-ui-rating"></i><i
                            class="icofont icofont-ui-rate-blank"></i><i class="icofont icofont-ui-rate-blank"></i><i
                            class="icofont icofont-ui-rate-blank"></i></li>
                    <li>2 stars</li>
                    <li>590 votes</li>
                    <li>Theme</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-12 m-t-20">
        <nav aria-label="...">
            <ul class="pagination pagination-primary justify-content-end">
                <li class="page-item disabled"><a class="page-link" href="javascript:void(0)" tabindex="-1">Previous</a>
                </li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                <li class="page-item active"><a class="page-link" href="javascript:void(0)">2<span
                            class="sr-only">(current)</span></a></li>
            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
            <li class="page-item"><a class="page-link" href="javascript:void(0)">Next</a></li>
        </ul>
    </nav>
</div></template>