<template>
    <div class="card">
                    <div class="card-header">
                        <h5>Overflow</h5>
                    </div>
                    <div class="card-body">
                        <pre class="helper-classes">.o-hidden {
overflow: hidden;
}
.o-visible {
overflow: visible;
}
.o-auto {
overflow: auto;
}</pre>
                    </div>
                </div>
</template>