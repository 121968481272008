<template>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-header pb-0">
        <h3>Click and Drag</h3>
      </div>
      <div class="card-body">
        <div class="scroll-bar-wrap">
          <div class="click-drag-handler scroll-demo p-0">
            <perfect-scrollbar class="click-drag-handler scroll-demo p-0" >
              <img src="../../../assets/images/banner/2.jpg" alt="" width="800" height="600">
          </perfect-scrollbar>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        
        }
    
}
</script>
<style scoped>
.ps {
  height: 300px;
}
</style>
