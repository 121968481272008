<template>
    <div class="col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Padding</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.p-0 {
                        padding: 0px;
                        }
                        .p-5 {
                        padding: 5px;
                        }
                        .p-10 {
                        padding: 10px;
                        }
                        .p-15 {
                        padding: 15px;
                        }
                        .p-20 {
                        padding: 20px;
                        }
                        .p-25 {
                        padding: 25px;
                        }
                        .p-30 {
                        padding: 30px;
                        }
                        .p-35 {
                        padding: 35px;
                        }
                        .p-40 {
                        padding: 40px;
                        }
                        .p-45 {
                        padding: 45px;
                        }
                        .p-50 {
                        padding: 50px;
                        }</pre>
                  </div>
                </div>
          </div>
</template>