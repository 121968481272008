<template>
    <div class="col-sm-12 col-md-6">
              <div class="card">
                <div class="card-header">
                  <h5>Dropdown With Disable</h5><span>Use a class <code>.dropdown-disabled</code></span>
                </div>
                <div class="card-body dropup-basic dropdown-basic">
                  <div class="dropup dropdown">
                    <button class="dropbtn btn-primary" type="button">Dropdown Button <span><i class="icofont icofont-arrow-up"></i></span></button>
                    <div class="dropup-content dropdown-content"><a href="#">Normal</a><a class="active" href="#">Active</a><a class="disabled" href="#">Disabled</a></div>
                  </div>
                </div>
              </div>
            </div>
</template>