<template>
  <a
    class="nav-link"
    target="_blank"
    href="https://vue-cuba-doc.vercel.app/getting_started"
  >
    <div>
      <i class="icon-settings"></i>
    </div>
    <span>Document</span>
  </a>
</template>

<script>
  export default {
    name: 'Document',
  };
</script>
