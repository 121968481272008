<template>
    <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Status Indicator</h5>
                  </div>
                  <div class="card-body avatar-showcase">
                    <div class="avatars">
                      <div class="avatar"><img class="img-100 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-90 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-80 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-70 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-60 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-50 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-40 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                      <div class="avatar"><img class="img-30 rounded-circle" src="@/assets/images/user/1.jpg" alt="#">
                        <div class="status"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>