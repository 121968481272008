<template>
<div class="col-12">
    <div class="card">
        <div class="card-header">
            <h5>large Button group</h5>
        </div>
        <div class="card-body btn-group-showcase">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-primary btn-lg" type="button">Left</button>
                        <button class="btn btn-primary btn-lg" type="button">Middle</button>
                        <button class="btn btn-primary btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-secondary btn-lg" type="button">Left</button>
                        <button class="btn btn-secondary btn-lg" type="button">Middle</button>
                        <button class="btn btn-secondary btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-success btn-lg" type="button">Left</button>
                        <button class="btn btn-success btn-lg" type="button">Middle</button>
                        <button class="btn btn-success btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-info btn-lg" type="button">Left</button>
                        <button class="btn btn-info btn-lg" type="button">Middle</button>
                        <button class="btn btn-info btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-warning btn-lg" type="button">Left</button>
                        <button class="btn btn-warning btn-lg" type="button">Middle</button>
                        <button class="btn btn-warning btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-danger btn-lg" type="button">Left</button>
                        <button class="btn btn-danger btn-lg" type="button">Middle</button>
                        <button class="btn btn-danger btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-light btn-lg" type="button">Left</button>
                        <button class="btn btn-light btn-lg" type="button">Middle</button>
                        <button class="btn btn-light btn-lg" type="button">Right</button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xxl-4 button-group-mb-sm">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-dark btn-lg" type="button">Left</button>
                        <button class="btn btn-dark btn-lg" type="button">Middle</button>
                        <button class="btn btn-dark btn-lg" type="button">Right</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
