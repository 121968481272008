<template>
<div class="col-md-6">
    <div class="card">
        <div class="card-header">
            <h5>Bold (700)</h5>
        </div>
        <div class="card-body typography">
            <h1 class="f-w-700">Heading 1</h1>
            <h2 class="f-w-700">Heading 2</h2>
            <h3 class="f-w-700">Heading 3</h3>
            <h4 class="f-w-700">Heading 4</h4>
            <h5 class="f-w-700">Heading 5</h5>
            <h6 class="f-w-700">Heading 6</h6>
        </div>
    </div>
</div>
<div class="col-md-6">
    <div class="card">
        <div class="card-header">
            <h5>Extra Bold (900)</h5>
        </div>
        <div class="card-body typography">
            <h1 class="f-w-900">Heading 1</h1>
            <h2 class="f-w-900">Heading 2</h2>
            <h3 class="f-w-900">Heading 3</h3>
            <h4 class="f-w-900">Heading 4</h4>
            <h5 class="f-w-900">Heading 5</h5>
            <h6 class="f-w-900">Heading 6</h6>
        </div>
    </div>
</div>
</template>
