<template>
<div class="col-xl-2 col-ed-3 d-xxl-block d-sm-none box-col-none">
    <div class="card get-card overflow-hidden">
        <div class="card-header card-no-border">
            <h5>Do You Want to Get</h5>
            <span class="f-14 f-w-500 f-light">Better Results?</span><a class="btn btn-primary btn-hover-effect" href="#">More details<span class="ms-1">
                    <svg class="fill-icon">
                        <use href="@/assets/svg/icon-sprite.svg#arrowright"></use>
                    </svg></span></a>
        </div>
        <div class="card-body pt-0">
            <div class="get-image text-center">
                <img class="img-fluid" src="@/assets/images/dashboard-3/better.png" alt="leptop with men vector" />
            </div>
        </div>
        <ul class="square-group">
            <li class="square-1 warning"></li>
            <li class="square-1 primary"></li>
            <li class="square-2 warning1"></li>
            <li class="square-3 danger"></li>
            <li class="square-4 light"></li>
            <li class="square-5 warning"></li>
            <li class="square-6 success"></li>
            <li class="square-7 success"></li>
        </ul>
    </div>
</div>
</template>
