<template>
<div class="col-xl-4">
    <div class="row">
        <div class="col-xl-12 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="media">
                        <vue-feather class="m-r-30" type="youtube"></vue-feather>
                        <div class="media-body">
                            <h6 class="f-w-500"> Vel illum qu</h6>
                            <p>Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="media">
                        <vue-feather class="m-r-30" type="youtube"></vue-feather>
                        <div class="media-body">
                            <h6 class="f-w-500"> Cum sociis natoqu</h6>
                            <p>Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="media">
                        <vue-feather class="m-r-30" type="youtube"></vue-feather>
                        <div class="media-body">
                            <h6 class="f-w-500">Donec pede justo</h6>
                            <p>Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
