<template>
    <div class="d-inline-block">
        <select class="form-control">
            <option>7:00 am</option>
            <option>7:30 am</option>
            <option>8:00 am</option>
            <option>8:30 am</option>
            <option>9:00 am</option>
            <option>9:30 am</option>
            <option>10:00 am</option>
            <option>10:30 am</option>
            <option>11:00 am</option>
            <option>11:30 am</option>
            <option>12:00 pm</option>
            <option>12:30 pm</option>
            <option>1:00 pm</option>
            <option>2:00 pm</option>
            <option>3:00 pm</option>
            <option>4:00 pm</option>
            <option>5:00 pm</option>
            <option>6:00 pm</option>
        </select>
    </div>
    <div class="d-inline-block">
        <label class="d-block mb-0">
            <input class="checkbox_animated" type="checkbox">Notification
        </label>
    </div>
    <div class="d-inline-block">
        <label class="d-block mb-0">
            <input class="checkbox_animated" type="checkbox">Mail
        </label>
    </div>
</template>