<template>
<li class="list-inline-item">
    <a href="#"><i class="icon-search"></i></a>
</li>
<li class="list-inline-item">
    <a href="#"><i class="icon-clip"></i></a>
</li>
<li class="list-inline-item">
    <a href="#"><i class="icon-headphone-alt"></i></a>
</li>
<li class="list-inline-item">
    <a href="#"><i class="icon-video-camera"></i></a>
</li>
</template>
