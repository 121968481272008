export const  inboxData = [{
    senderName: "Pork Lorn",
    sendDate: "15",
    sendMonth: "July",
    sendYear: "2018",
    msg: "Mattis luctus. Donec nisi diam,"
},
{
    senderName: "Lorm lpsa",
    sendDate: "20",
    sendMonth: "January",
    msg: "Mattis luctus. Donec nisi diam,"
},
{
    senderName: "Hileri makr",
    sendDate: "20",
    sendMonth: "Aug",
    sendYear: "2018",
    msg: "Mattis luctus. Donec nisi diam,"
}, {
    senderName: "Lion Lorpa",
    sendDate: "20",
    sendMonth: "Jun",
    sendYear: "2016",
    msg: "Mattis luctus. Donec nisi diam,"
},
{
    senderName: "Solvn Relto",
    sendDate: "25",
    sendMonth: "July",
    sendYear: "2015",
    msg: "Mattis luctus. Donec nisi diam,"
},
{
    senderName: "Repro Soft",
    sendDate: "15",
    sendMonth: "July",
    sendYear: "2018",
    msg: "Mattis luctus. Donec nisi diam,"
},
{
    senderName: "Lion Lorpa",
    sendDate: "02",
    sendMonth: "Jun",
    sendYear: "2016",
    msg: "Mattis luctus. Donec nisi diam,"
}
]