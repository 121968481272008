<template>
  <div>
    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light" src="../assets/images/logo/logo.png" alt="loginpage" />
                  <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="loginpage" />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" required v-model="user.email.value">
                    <span class="validate-error" v-if="!user.email.value || !validEmail(user.email.value)">
                      {{ user.email.errormsg }}
                    </span>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input class="form-control" type="password" required v-model="user.password.value">
                      <span class="validate-error" v-if="user.password.value.length < 7">
                        {{ user.password.errormsg }}
                      </span>
                      <div class="show-hide"><span class="show"></span></div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" type="submit">Sign in</button>
                    </div>
                  </div>
                  <span class="validate-error" v-if="authError">{{ authError }}</span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'login',
  data() {
    return {
      user: {
        email: {
          value: '',
          errormsg: ''
        },
        password: {
          value: '',
          errormsg: ''
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      authError: 'auth/authError'
    })
  },
  methods: {
    login() {
      if (!this.user.password.value || this.user.password.value.length < 7) {
        this.user.password.errormsg = 'Min length 7';
      } else {
        this.user.password.errormsg = '';
      }

      if (!this.user.email.value) {
        this.user.email.errormsg = 'Empty not allowed';
      } else if (!this.validEmail(this.user.email.value)) {
        this.user.email.errormsg = 'Valid email required.';
      } else {
        this.user.email.errormsg = '';
      }

      // Firebase log in
      if (!this.user.email.errormsg && !this.user.password.errormsg) {
        this.$store.dispatch('auth/signIn', {
          email: this.user.email.value,
          password: this.user.password.value
        })
          .then(() => {
            this.logging = true
            this.$router.push('/');
          })
          .catch((error) => {
            console.error(error);
          });
      }


      // if (!this.user.email.errormsg && !this.user.password.errormsg && this.user.email.value != 'test@admin.com' || this.user.password.value != 'test@123456') {
      //   alert("wrong credenstials")
      // }
      // if (!this.user.email.errormsg && !this.user.password.errormsg && this.user.email.value == 'test@admin.com' && this.user.password.value == 'test@123456') {

      //   // this.result = { email: this.user.email.value, password: this.user.password.value }

      //   // localStorage.setItem('User', JSON.stringify({ email: this.user.email.value, useer: true }))
      //   // this.logging = true
      //   // this.$router.push('/')


      // }

    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>