<template>
    <div class="col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Vertical Align</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.baseline {
vertical-align: baseline;
}
.sub {
vertical-align: sub;
}
.super {
vertical-align: super;
}
.top {
vertical-align: top;
}
.text-top {
vertical-align: text-top;
}
.middle {
vertical-align: middle;
}
.bottom {
vertical-align: bottom;
}
.text-bottom {
vertical-align: text-bottom;
}
.initial {
vertical-align: initial;
}
.inherit {
vertical-align: inherit;
}</pre>
                  </div>
                </div>
              </div>
</template>