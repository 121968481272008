<template>
    <div class="col-xl-12 col-sm-6">
            <div class="card crypto-main-card">
              <div class="card-body">
                <div class="deposit-wrap">
                  <div>
                    <h5>Crypto currency just got even better.</h5>
                    <p>Sometime called crypto-currency or crypto.</p>
                    <button class="btn btn-white f-w-500">Deposit Now</button>
                  </div>
                  <img
                    src="@/assets/images/dashboard-4/crypto.png"
                    alt="crypto"
                  />
                </div>
              </div>
            </div>
          </div>
</template>