<template>
    <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Select </h5>
          </div>
          <div>
            <div class="card-block row">
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="table-responsive">
                  <table class="table table-bordered checkbox-td-width">
                    <tbody>
                      <tr>
                        <td>Single Select</td>
                        <td class="w-50">
                          <select class="form-select" id="exampleFormControlSelect45">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </td>
                        <td><span>Use for basic select control.</span></td>
                      </tr>
                      <tr>
                        <td>Disabled Select Mode</td>
                        <td>
                          <select class="form-select" id="exampleFormControlSelect4" disabled>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </td>
                        <td><span>Use for disabled select control add <code>disabled</code>.</span></td>
                      </tr>
                      <tr>
                        <td>Large Select Mode</td>
                        <td>
                          <select class="form-select form-control-lg mb-10" id="exampleFormControlSelect1">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                          <select class="form-select form-control-sm mt-5 mb-10" id="exampleFormControlSelect3">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </td>
                        <td><span>Use for large and small select control add class<code>form-control-*</code><code>lg, sm</code>.</span></td>
                      </tr>
                      <tr>
                        <td>Example multiple select</td>
                        <td>
                          <select class="form-control" id="exampleFormControlSelect22" multiple>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </td>
                        <td><span>Use for multi select control add code <code>multiple</code>on select.</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>