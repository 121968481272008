<template>
    <div class="col-md-12" v-if="data">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">Rooms</h4>
          <div class="card-options">
            <a class="card-options-collapse" href="#" data-bs-toggle="card-collapse">
              <i class="fe fe-chevron-up"></i>
            </a>
            <a class="card-options-remove" href="#" data-bs-toggle="card-remove">
              <i class="fe fe-x"></i>
            </a>
          </div>
        </div>
        <div class="table-responsive add-project">
          <table class="table card-table table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Room Type</th>
                <th>Topic</th>
                <th>Messages</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(room, index) in data" :key="index">
                <td>{{ room.roomType }}</td>
                <td>{{ room.roomType === 'guru' ? 'Berry' : room.topicName }}</td>
                <td>{{ room.messages?.length }}</td>
                <td>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="openModal(room)"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
           <!-- Modal for viewing chat messages -->
    <div
      v-if="selectedRoom"
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <chat-modal :data="selectedRoom.messages" />
    </div>
        </div>
      </div>
    </div>

   
  </template>

  <script>
  import { Modal } from 'bootstrap';
  import chatModal from "./chatModal.vue";
  
  export default {
    components: {
      chatModal,
    },
    name: 'rooms',
    props: {
      data: {
        type: Array,
      }
    },
    data() {
      return {
        selectedRoom: null
      };
    },
    mounted() {
      console.log('Rooms:', this.data);
    },
    methods: {
      openModal(room) {
        console.log('Opening modal for room:', room);
        this.selectedRoom = room;
        this.$nextTick(() => {
          const modalElement = document.getElementById('exampleModal');
          const modalInstance = new Modal(modalElement);
          modalInstance.show();
        });
      }
    }
  };
  </script>