<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 xl-40 box-col-40">
        <div class="md-sidebar"><a class="md-sidebar-toggle btn btn-primary" href="javascript:void(0)">Knowledge filter</a>
          <div class="md-sidebar-aside custom-scrollbar custom-scrollbar">
            <div class="default-according style-1 faq-accordion job-accordion" id="accordionoc">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="mb-0 p-0">
                        <button class="btn btn-link ps-0" data-bs-toggle="collapse" data-bs-target="#collapseicon1">Knowledge Categories</button>
                      </h5>
                    </div>
                    <div class="collapse show" id="collapseicon1" aria-labelledby="collapseicon1" data-bs-parent="#accordion">
                      <div class="categories">
                        <div class="learning-header"><span class="f-w-600">Design</span></div>
                        <ul>
                          <li><a href="javascript:void(0)">UI Design </a><span class="badge badge-primary pull-right">28</span></li>
                          <li><a href="javascript:void(0)">UX Design </a><span class="badge badge-primary pull-right">35</span></li>
                          <li><a href="javascript:void(0)">Interface Design </a><span class="badge badge-primary pull-right">17</span></li>
                          <li><a href="javascript:void(0)">User Experience </a><span class="badge badge-primary pull-right">26</span></li>
                        </ul>
                      </div>
                      <div class="categories pt-0">
                        <div class="learning-header"><span class="f-w-600">Development</span></div>
                        <ul>
                          <li><a href="javascript:void(0)">Frontend Development</a><span class="badge badge-primary pull-right">48</span></li>
                          <li><a href="javascript:void(0)">Backend Development</a><span class="badge badge-primary pull-right">19</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="mb-0 p-0">
                        <button class="btn btn-link ps-0" data-bs-toggle="collapse" data-bs-target="#collapseicon2" aria-expanded="true" aria-controls="collapseicon2">Upcoming Courses</button>
                      </h5>
                    </div>
                    <div class="collapse show" id="collapseicon2" aria-labelledby="collapseicon2" data-bs-parent="#accordion">
                      <div class="upcoming-course card-body">
                        <div class="d-flex">
                          <div class="flex-grow-1"><span class="f-w-600">UX Development</span>
                            <h6>Course By <a href="javascript:void(0)"> Lorem ipsum</a></h6>
                            <ul class="rating">
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star-half-o font-warning"></i></li>
                            </ul>
                          </div>
                          <div>
                            <h5 class="mb-0 p-0 font-primary">18</h5><span class="d-block">Dec</span>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="flex-grow-1"><span class="f-w-600">Business Analyst</span>
                            <h6>Course By <a href="javascript:void(0)">Lorem ipsum </a></h6>
                            <ul class="rating">
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star-half-o font-warning"></i></li>
                            </ul>
                          </div>
                          <div>
                            <h5 class="mb-0 p-0 font-primary">28</h5><span class="d-block">Dec</span>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="flex-grow-1"><span class="f-w-600">Web Development</span>
                            <h6>Course By <a href="javascript:void(0)">Lorem ipsum </a></h6>
                            <ul class="rating">
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star font-warning"></i></li>
                              <li><i class="fa fa-star-half-o font-warning"></i></li>
                            </ul>
                          </div>
                          <div>
                            <h5 class="mb-0 p-0 font-primary">5</h5><span class="d-block">Jan                         </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-9 xl-60 box-col-60">
        <div class="blog-single">
          <div class="blog-box">
            <div class="card"><img class="img-fluid w-100" src="@/assets/images/faq/1.jpg" alt="blog-main"></div>
            <div class="card">
              <div class="card-body">
                <div class="blog-details">                                  
                  <h4 class="mt-0">Why Choose Knowledge Course ?</h4>
                  <div class="single-blog-content-top">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>