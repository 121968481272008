<template>
    <div class="col-sm-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h5>Pills with number</h5>
                    </div>
                    <div class="card-body"><span class="badge rounded-pill badge-primary">1</span><span class="badge rounded-pill badge-secondary">2</span>
                        <span class="badge rounded-pill badge-success">3</span><span class="badge rounded-pill badge-info">4</span><span class="badge rounded-pill badge-warning">5</span><span class="badge rounded-pill badge-danger">6</span><span class="badge rounded-pill badge-light text-dark">7</span>
                        <span class="badge rounded-pill badge-dark">8</span></div>
                </div>
            </div>
</template>