<template>
<div class="col-sm-12 col-lg-6">
    <div class="card">
        <div class="card-header">
            <h5>Theme Color Alerts</h5><span>Theme color Archive just adding class <code>.dark</code></span>
        </div>
        <div class="card-body">
            <div class="alert alert-primary dark" role="alert">
                <p>This is a info alert—check it out!</p>
            </div>
            <div class="alert alert-secondary dark" role="alert">
                <p>This is a light alert—check it out!</p>
            </div>
            <div class="alert alert-success dark" role="alert">
                <p>This is a success alert—check it out!</p>
            </div>
            <div class="alert alert-info dark" role="alert">
                <p>This is a info alert—check it out!</p>
            </div>
            <div class="alert alert-warning dark" role="alert">
                <p>This is a warning alert—check it out!</p>
            </div>
            <div class="alert alert-danger dark" role="alert">
                <p>This is a danger alert—check it out!</p>
            </div>
            <div class="alert alert-light dark" role="alert">
                <p>This is a light alert—check it out!</p>
            </div>
            <div class="alert alert-dark dark" role="alert">
                <p>This is a dark alert—check it out!</p>
            </div>
        </div>
    </div>
</div>
</template>
