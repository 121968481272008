<template>
    <div class="col-md-6 col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Badge Color</h5>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.badge-primary {
background-color: #7366ff;
}
.badge-secondary {
background-color: #f73164;
}
.badge-success {
background-color: #51bb25;
}
.badge-danger {
background-color: #dc3545;
}
.badge-info {
background-color: #a927f9;
}
.badge-light {
background-color: #f4f4f4;
}
.badge-dark {
background-color: #2c323f;
}
.badge-warning {
background-color: #f8d62b;
}</pre>
                  </div>
                </div>
              </div>
    
</template>