<template>
    <div class="col-md-12" v-if="data">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">Watchers</h4>
          <div class="card-options">
            <a class="card-options-collapse" href="#" data-bs-toggle="card-collapse">
              <i class="fe fe-chevron-up"></i>
            </a>
            <a class="card-options-remove" href="#" data-bs-toggle="card-remove">
              <i class="fe fe-x"></i>
            </a>
          </div>
        </div>
        <div class="table-responsive add-project">
          <table class="table card-table table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(watcher, index) in data" :key="index">
                <td>{{ watcher.symbol }}</td>
                <td>{{ watcher.createdAt }}</td>
              </tr>
            </tbody>
          </table>
           <!-- Modal for viewing chat messages -->
        </div>
      </div>
    </div>

   
  </template>

  <script>
  
  export default {
    name: 'watchers',
    props: {
      data: {
        type: Array,
      }
    },
    mounted() {
      console.log('Watchers:', this.data);
    }
  };
  </script>