<template>
    <div class="col-sm-12 col-md-6">
              <div class="card">
                <div class="card-header">
                  <h5>Dropdown With Header</h5><span>Use a class <code>.dropdown-header</code></span>
                </div>
                <div class="card-body dropdown-basic">
                  <div class="dropdown">
                    <button class="dropbtn btn-primary" type="button">Dropdown Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                    <div class="dropdown-content">
                      <h5 class="dropdown-header">Dropdown header</h5><a href="#">Link 1</a><a href="#">Link 2</a>
                      <h5 class="dropdown-header">Dropdown header</h5><a href="#">Another Link</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</template>