<template>
    <div class="col-sm-12 col-md-6">
              <div class="card">
                <div class="card-header">
                  <h5>Dropdown With Divider</h5><span>Use a class </span>
                </div>
                <div class="card-body dropdown-basic">
                  <div class="dropdown">
                    <button class="dropbtn btn-primary" type="button">Dropdown Button <span><i class="icofont icofont-arrow-down"></i></span></button>
                    <div class="dropdown-content"><a href="#">Link 1</a><a href="#">Link 2</a><a href="#">Link 3</a><a href="#">Another Link</a></div>
                  </div>
                </div>
              </div>
            </div>
</template>