<template>
    <div class="col-sm-12 box-col-12">
        <div class="card hovercard text-center">
          <div class="cardheader socialheader"></div>
          <div class="user-image">
            <div class="avatar"><img alt="" src="@/assets/images/user/1.jpg"></div>
            <div class="icon-wrapper"><i class="icofont icofont-pencil-alt-5"></i></div>
            <ul class="share-icons">
              <li><a class="social-icon bg-primary" href="#"><i class="fa fa-smile-o"></i></a></li>
              <li><a class="social-icon bg-secondary" href="#"><i class="fa fa-wechat"></i></a></li>
              <li><a class="social-icon bg-warning" href="#"><i class="fa fa-share-alt"></i></a></li>
            </ul>
          </div>
          <div class="info market-tabs p-0">
            <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
              <li class="nav-item"><a class="nav-link active" id="top-timeline" data-bs-toggle="tab" href="#timeline" role="tab" aria-controls="timeline" aria-selected="true">Timeline</a></li>
              <li class="nav-item"><a class="nav-link" id="top-about" data-bs-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="false">About
              </a></li>
              <li class="nav-item">
                <div class="user-designation"></div>
                <div class="title"><a target="_blank" href="">ElANA</a></div>
                <div class="desc mt-2">general manager</div>
              </li>
              <li class="nav-item"><a class="nav-link" id="top-friends" data-bs-toggle="tab" href="#friends" role="tab" aria-controls="friends" aria-selected="false">Friends</a></li>
              <li class="nav-item"><a class="nav-link" id="top-photos" data-bs-toggle="tab" href="#photos" role="tab" aria-controls="photos" aria-selected="false">Photos</a></li>
            </ul>
          </div>
        </div>
      </div>
      
</template>