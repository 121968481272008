<template>
    <div class="card">
                    <div class="card-header">
                        <h5>Font Style</h5>
                    </div>
                    <div class="card-body">
                        <pre class="helper-classes">.f-s-normal {
font-style: normal;
}
.f-s-italic {
font-style: italic;
}
.f-s-oblique {
font-style: oblique;
}
.f-s-initial {
font-style: initial;
}
.f-s-inherit {
font-style: inherit;
}</pre>
                    </div>
                </div>
</template>