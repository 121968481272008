import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCwzOtcrwpxLLPD8GAstEnbUOj22goVlN0",
  authDomain: "thaurusguru.firebaseapp.com",
  projectId: "thaurusguru",
  storageBucket: "thaurusguru.appspot.com",
  messagingSenderId: "678213818828",
  appId: "1:678213818828:web:399240129a58449b82a569",
  measurementId: "G-HTFM0CZQMZ"
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);