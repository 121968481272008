<template>
    <div class="card">
                    <div class="card-header">
                        <h5>Border Radius</h5>
                    </div>
                    <div class="card-body">
                        <pre class="helper-classes">.b-r-0 {
border-radius: 0px !important;
}
.b-r-1 {
border-radius: 1px !important;
}
.b-r-2 {
border-radius: 2px !important;
}
.b-r-3 {
border-radius: 3px !important;
}
.b-r-4 {
border-radius: 4px !important;
}
.b-r-5 {
border-radius: 5px !important;
}
.b-r-6 {
border-radius: 6px !important;
}
.b-r-7 {
border-radius: 7px !important;
}
.b-r-8 {
border-radius: 8px !important;
}
.b-r-9 {
border-radius: 9px !important;
}
.b-r-10 {
border-radius: 10px !important;
}</pre>
                    </div>
                </div>
</template>