<template>
<div class="col-xxl-4 col-lg-6 box-col-6">
    <div class="card">
        <div class="card-header py-4">
            <h5>COD</h5>
        </div>
        <div class="card-body">
            <form class="theme-form row">
                <div class="mb-3 col-6 p-r-0">
                    <input class="form-control" type="text" placeholder="First Name">
                </div>
                <div class="mb-3 col-6">
                    <input class="form-control" type="text" placeholder="Last name">
                </div>
                <div class="mb-3 col-6 p-r-0">
                    <input class="form-control" type="text" placeholder="Pincode">
                </div>
                <div class="mb-3 col-6">
                    <input class="form-control" type="number" placeholder="Enter mobile number">
                </div>
                <div class="mb-3 col-6 p-r-0">
                    <input class="form-control" type="text" placeholder="State">
                </div>
                <div class="mb-3 col-6">
                    <input class="form-control" type="text" placeholder="City">
                </div>
                <div class="mb-3 col-12">
                    <textarea class="form-control" rows="3" placeholder="Address"></textarea>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary btn-block" type="button" title="">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
</template>
