<template>
    <div class="col-xl-4 col-sm-6">
        <div class="card bg-primary">
            <div class="card-body">
                <div class="media faq-widgets">
                    <div class="media-body">
                        <h5>Articles</h5>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                            mus.</p>
                    </div><vue-feather type="book-open"></vue-feather>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-sm-6">
        <div class="card bg-primary">
            <div class="card-body">
                <div class="media faq-widgets">
                    <div class="media-body">
                        <h5>Knowledgebase</h5>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                            mus.</p>
                    </div><vue-feather type="aperture"></vue-feather>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4">
        <div class="card bg-primary">
            <div class="card-body">
                <div class="media faq-widgets">
                    <div class="media-body">
                        <h5>Support</h5>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                            mus.</p>
                </div><vue-feather type="file-text"></vue-feather>
            </div>
        </div>
    </div>
</div></template>