<template>
    <div class="col-form-label">Inverse Select</div>
    <select class="form-select form-control-inverse-fill" name="select">
        <option value="opt1">Select One Value Only</option>
        <option value="opt2">Type 2</option>
        <option value="opt3">Type 3</option>
        <option value="opt4">Type 4</option>
        <option value="opt5">Type 5</option>
        <option value="opt6">Type 6</option>
        <option value="opt7">Type 7</option>
        <option value="opt8">Type 8</option>
    </select>
</template>