<template>
  <div class="col-xl-6 set-col-12 box-col-12">
    <div class="card">
      <div class="blog-box blog-list row">
        <div class="col-sm-5"><img class="img-fluid sm-100-w" src="@/assets/images/blog/blog-2.jpg" alt=""></div>
        <div class="col-sm-7">
          <div class="blog-details">
            <div class="blog-date"><span>02</span> January 2018</div>
            <h6>Perspiciatis unde omnis iste natus</h6>
            <div class="blog-bottom-content">
              <ul class="blog-social">
                <li>by: Admin</li>
                <li>0 Hits</li>
              </ul>
              <hr>
              <p class="mt-0">inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="blog-box blog-list row">
        <div class="col-sm-5"><img class="img-fluid sm-100-w" src="@/assets/images/blog/blog-3.jpg" alt=""></div>
        <div class="col-sm-7">
          <div class="blog-details">
            <div class="blog-date"><span>03</span> January 2018</div>
            <h6>Perspiciatis unde omnis iste natus</h6>
            <div class="blog-bottom-content">
              <ul class="blog-social">
                <li>by: Admin</li>
                <li>02 Hits</li>
              </ul>
              <hr>
              <p class="mt-0">inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>