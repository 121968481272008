<template>
<div class="col-sm-12 listing">
    <div class="card">
        <div class="card-header">
            <h5>Listing Typography</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <h6 class="sub-title">Unorder list</h6>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h6 class="sub-title">Order list</h6>
                    <ol>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>
                            <ol>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                            </ol>
                        </li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ol>
                </div>
                <div class="col-md-4">
                    <h6 class="sub-title">Order list</h6>
                    <dl>
                        <dt>Lorem ipsum dolor sit amet</dt>
                        <dd>- ipsum dolor sit amet</dd>
                        <dt>Lorem ipsum dolor sit amet</dt>
                        <dd>- ipsum dolor sit amet</dd>
                        <dt>Lorem ipsum dolor sit amet</dt>
                        <dd>- ipsum dolor sit amet</dd>
                        <dt>Lorem ipsum dolor sit amet</dt>
                        <dd>- ipsum dolor sit amet</dd>
                        <dt>Lorem ipsum dolor sit amet</dt>
                        <dd>- ipsum dolor sit amet</dd>
                    </dl>
                </div>
                <div class="col-md-4">
                    <h6 class="sub-title">Fontawesome list</h6>
                    <ul>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-angle-double-right txt-primary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h6 class="sub-title">ICO Icon list</h6>
                    <ul>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="fa fa-caret-right txt-secondary m-r-10"></i>Lorem ipsum dolor sit amet</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h6 class="sub-title">Themfy list</h6>
                    <ul>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                        <li><i class="icofont icofont-ui-rate-add txt-success m-r-10"></i>Lorem ipsum dolor sit amet</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
