<template>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="card hovercard" v-for="(item, index) in data">
  
                <div class="card-body">
                <p>{{item.content}}</p>
                <br>
                    <p><span>
                {{formatDate(item.timestamp)}}
              </span></p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </template>


<!-- <template>
    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">   
                       
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
</template> -->
<script>
import chatBody from "../../chat/chatApp/chatBody.vue"; 

export default {
  name: 'chatModal',
  componetns: {
    chatBody
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  mounted() {
    console.log('Messages:', this.data);
  },
  methods: {
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleString();
    }
  }
};


</script>