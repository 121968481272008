<template>
<div class="col-sm-12 col-lg-6">
    <div class="card">
        <div class="card-header">
            <h5>Light Alerts</h5><span>Alerts are available for any length of text, as well as an optional dismiss button.</span>
        </div>
        <div class="card-body">
            <div class="alert alert-primary" role="alert">
                <p>This is a primary alert—check it out!</p>
            </div>
            <div class="alert alert-secondary" role="alert">
                <p>This is a secondary alert—check it out!</p>
            </div>
            <div class="alert alert-success" role="alert">
                <p>This is a success alert—check it out!</p>
            </div>
            <div class="alert alert-info" role="alert">
                <p>This is a info alert—check it out!</p>
            </div>
            <div class="alert alert-warning" role="alert">
                <p>This is a warning alert—check it out!</p>
            </div>
            <div class="alert alert-danger" role="alert">
                <p>This is a danger alert—check it out!</p>
            </div>
            <div class="alert alert-light" role="alert">
                <p>This is a light alert—check it out!</p>
            </div>
            <div class="alert alert-dark" role="alert">
                <p>This is a light alert—check it out!</p>
            </div>
        </div>
    </div>
</div>
</template>
