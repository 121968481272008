import { getDashboardData } from '../../api/dashboard';

const state = {
  dashboardData: null,
  signals: null,
  watchers: null,
  users: null,
  messages: null,
  analysis: null,
  recentRegistrations: null,
};

const getters = {
  dashboardData: (state) => state.dashboardData,
};

const mutations = {
  setDashboardData:(state,payload)=>{
    state.dashboardData = payload;
    state.signals = payload.signals;
    state.watchers = payload.watchers;
    state.users = payload.users;
    state.messages = payload.messages;
    state.analysis = payload.analysis;
    state.recentRegistrations = payload.recentRegistrations;
  },
};

const actions = {
   async fetchDashboardData({ commit }) {
    try {
      const response = await getDashboardData();
      commit('setDashboardData', response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};