export const  imagearray = [
    {
      id: 1,   
      imagepath: require('@/assets/images/masonry/1.jpg')
    },
    {
      id: 2,       
      imagepath: require('@/assets/images/masonry/2.jpg')
    },
    {
      id: 3,        
      imagepath: require('@/assets/images/masonry/3.jpg')
    },
    {
      id: 4,    
      imagepath: require('@/assets/images/masonry/4.jpg')
    },
    {
      id: 5,  
      imagepath: require('@/assets/images/masonry/5.jpg')
    },
    {
      id: 6,       
      imagepath: require('@/assets/images/masonry/6.jpg')
    },
    {
      id: 7,        
      imagepath: require('@/assets/images/masonry/8.jpg')
    },
    {
      id: 8,        
      imagepath: require('@/assets/images/masonry/9.jpg')
    },
    {
      id: 9,       
      imagepath: require('@/assets/images/masonry/10.jpg')
    },
    {
      id: 10,      
      imagepath: require('@/assets/images/masonry/11.jpg')
    },
    {
      id: 11,         
      imagepath: require('@/assets/images/masonry/12.jpg')
    },
    {
      id: 12,          
      imagepath: require('@/assets/images/masonry/14.jpg')
    },
    {
      id: 13,          
      imagepath: require('@/assets/images/masonry/15.jpg')
    },
    {
      id: 14,
      imagepath: require('@/assets/images/masonry/13.jpg')
    }
  ]