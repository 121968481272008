<template>
  <div class="row">
    <div class="col-xxl-6">
      <h6 class="mb-2">About 6,000 results (0.60 seconds)</h6>
      <div class="media info-block">
        <iframe class="me-3" width="200" height="100" src="https://www.youtube.com/embed/CJnfAXlBRTE"></iframe>
        <div class="media-body"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
          <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
          <div class="star-ratings">
            <ul class="search-info">
              <li>3 stars</li>
              <li>590 votes</li>
              <li>Theme</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3" width="200" height="100" src="https://www.youtube.com/embed/wpmHZspl4EM"></iframe>
        <div class="media-body"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
          <h6>Lorem Ipsum is simply dummy text of the printing.</h6>
          <div class="star-ratings">
            <ul class="search-info">
              <li>3 stars</li>
              <li>590 votes</li>
              <li>Theme</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3" width="200" height="100" src="https://www.youtube.com/embed/-L4gEk7cOfk"></iframe>
        <div class="media-body"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
          <h6>Morbi eget quam et purus commodo dapibus.</h6>
          <div class="star-ratings">
            <ul class="search-info">
              <li>3 stars</li>
              <li>590 votes</li>
              <li>Theme</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-6">
      <p class="pb-4">About 6,000 results (0.60 seconds)</p>
      <div class="media info-block">
        <iframe class="me-3" width="200" height="100" src="https://www.youtube.com/embed/CJnfAXlBRTE"></iframe>
        <div class="media-body"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
          <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
          <div class="star-ratings">
            <ul class="search-info">
              <li>3 stars</li>
              <li>590 votes</li>
              <li>Theme</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3" width="200" height="100" src="https://www.youtube.com/embed/-L4gEk7cOfk"></iframe>
        <div class="media-body"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
          <h6>Morbi eget quam et purus commodo dapibus.</h6>
          <div class="star-ratings">
            <ul class="search-info">
              <li>3 stars</li>
              <li>590 votes</li>
              <li>Theme</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3" width="200" height="100" src="https://www.youtube.com/embed/wpmHZspl4EM"></iframe>
        <div class="media-body"><a href="">https://themeforest.net/user/pixelstrap/portfolio</a>
          <h6>Lorem Ipsum is simply dummy text of the printing.</h6>
          <div class="star-ratings">
            <ul class="search-info">
              <li>3 stars</li>
              <li>590 votes</li>
              <li>Theme</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12 m-t-20">
      <div>
        <nav aria-label="...">
          <ul class="pagination pagination-primary">
            <li class="page-item disabled"><a class="page-link" href="#" tabindex="-1">Previous</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item active"><a class="page-link" href="#">2 <span class="sr-only">(current)</span></a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>