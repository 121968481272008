<template>
<div class="tab-pane fade" id="pills-darkprofile" role="tabpanel" aria-labelledby="pills-darkprofile-tab">
    <div class="email-content">
        <div class="email-top">
            <div class="row">
                <div class="col-md-6 xl-100 col-sm-12">
                    <div class="media"><img class="me-3 rounded-circle" src="@/assets/images/user/user.png" alt="">
                        <div class="media-body">
                            <h6>Lorm lpsa <small><span>(20</span> January) <span>6:00</span> AM</small></h6>
                            <p>Mattis luctus. Donec nisi diam text.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 xl-100">
                    <div class="float-end d-flex">
                        <p class="user-emailid">Lormlpsa<span>23</span>@company.com</p><i class="fa fa-star-o f-18 mt-1"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="email-wrapper">
            <p>Hello</p>
            <p>Dear Sir Good Morning,</p>
            <h5>Elementum varius nisi vel tempus. Donec eleifend egestas viverra.</h5>
            <p class="m-b-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non diam facilisis, commodo libero et, commodo sapien. Pellentesque sollicitudin massa sagittis dolor facilisis, sit amet vulputate nunc molestie. Pellentesque maximus nibh id luctus porta. Ut consectetur dui nec nulla mattis luctus. Donec nisi diam, congue vitae felis at, ullamcorper bibendum tortor. Vestibulum pellentesque felis felis. Etiam ac tortor felis. Ut elit arcu, rhoncus in laoreet vel, gravida sed tortor.</p>
            <p>In elementum varius nisi vel tempus. Donec eleifend egestas viverra. Donec dapibus sollicitudin blandit. Donec scelerisque purus sit amet feugiat efficitur. Quisque feugiat semper sapien vel hendrerit. Mauris lacus felis, consequat nec pellentesque viverra, venenatis a lorem. Sed urna</p>
            <hr>
            <div class="d-inline-block">
                <h6 class="text-muted"><i class="icofont icofont-clip"></i> ATTACHMENTS</h6><a class="text-muted text-end right-download" href="#"><i class="fa fa-long-arrow-down me-2"></i>Download All</a>
                <div class="clearfix"></div>
            </div>
            <div class="attachment">
                <ul class="list-inline">
                    <li class="list-inline-item"><img class="img-fluid" src="@/assets/images/email/1.jpg" alt=""></li>
                    <li class="list-inline-item"><img class="img-fluid" src="@/assets/images/email/2.jpg" alt=""></li>
                    <li class="list-inline-item"><img class="img-fluid" src="@/assets/images/email/3.jpg" alt=""></li>
                </ul>
            </div>
            <hr>
            <div class="action-wrapper">
                <ul class="actions">
                    <li><a class="text-muted" href="#"><i class="fa fa-reply me-2"></i>Reply</a></li>
                    <li><a class="text-muted" href="#"><i class="fa fa-reply-all me-2"></i>Reply All</a></li>
                    <li><a class="text-muted" href="#"><i class="fa fa-share me-2"></i></a>Forward</li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>
