// src/api/user.js
import axios from 'axios';


// Function to get all users
export async function getUsers(){
  return axios.get('api/users/all');
};

// Function to get all users groups by promo code
export async function getUsersGroupsByPromoCode(){
  return axios.get('api/users/group-by-promo-code');
};

// Function to get a user by ID
export async function getUser(id) {
  const url = `/api/users/profile/${id}`;
  console.log(url)
  return axios.get(url);
};

// Function to create a new user
export async function createUser(data){
  return axios.post('api//users', data);
};

// Function to update a user by ID 
export async function updateUser(id, data){
  return axios.put(`api//users/${id}`, data);
};

// Function to delete a user by ID
export async function deleteUser(id){
  return axios.delete(`api//users/${id}`);
};