<template>
    
            <div class="col-sm-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h5>Contextual variations</h5>
                    </div>
                    <div class="card-body"><span class="badge badge-primary">Primary</span><span class="badge badge-secondary">Secondary</span><span class="badge badge-success">Success</span><span class="badge badge-info">Info</span><span class="badge badge-warning">Warning</span><span class="badge badge-danger">Danger</span><span class="badge badge-light text-dark">Light</span><span class="badge badge-dark tag-pills-sm-mb">Dark</span></div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h5>Contextual variations</h5>
                    </div>
                    <div class="card-body"><span class="badge rounded-pill badge-primary">Primary</span><span class="badge rounded-pill badge-secondary">Secondary</span><span class="badge rounded-pill badge-success">Success</span><span class="badge rounded-pill badge-info">Info</span><span class="badge rounded-pill badge-warning">Warning</span><span class="badge rounded-pill badge-danger">Danger</span><span class="badge rounded-pill badge-light text-dark">Light</span><span class="badge rounded-pill badge-dark tag-pills-sm-mb">Dark</span></div>
                </div>
            </div>
</template>