<template>
    <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h5>Links and buttons</h5>
                </div>
                <div class="card-body">
                  <div class="list-group"><a class="list-group-item list-group-item-action active" href="javascript:void(0)">Cras justo odio</a><a class="list-group-item list-group-item-action" href="javascript:void(0)">Dapibus ac facilisis in</a><a class="list-group-item list-group-item-action" href="javascript:void(0)">Morbi leo risus</a><a class="list-group-item list-group-item-action" href="javascript:void(0)">Porta ac consectetur ac</a><a class="list-group-item list-group-item-action disabled" href="javascript:void(0)">Vestibulum at eros</a></div>
                </div>
              </div>
            </div>
</template>