<template>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>Pills with Icon</h5>
            </div>
            <div class="card-body">
                <a class="badge rounded-pill badge-primary p-2">
                    <vue-feather type="dollar-sign"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-secondary p-2">
                    <vue-feather type="headphones"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-success p-2">
                    <vue-feather type="link"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-info p-2">
                    <vue-feather type="github"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-warning p-2">
                    <vue-feather type="award"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-danger p-2">
                    <vue-feather type="activity"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-light text-dark p-2">
                    <vue-feather type="heart"></vue-feather>
                </a>
                <a class="badge rounded-pill badge-dark p-2">
                    <vue-feather type="mail"></vue-feather>
                </a>
            </div>
        </div>
    </div>
</template>