<template>
    <div>
        <Breadcrumbs main="Dashboard" title="default" />

        <div class="container-fluid">
            <div class="row widget-grid">
                <RecentRegistrationsCard />
                <StatCards />
<!--                 <OrdersProfitCard />
                <OverallBalanceCard /> -->
                
<!--                 <ActivitiesCard />
                <RecentSalesCard />
                <TimelineCard />
                <ProAccountCard />
                <TotalUsersGrowthCard />
                <PaperNoteCard /> -->
            </div>
        </div>

    </div>
</template>

<script>
import StatCards from "./thaurusguru/StatCards.vue";
import OrdersProfitCard from "./default/OrdersProfitCard.vue";
import OverallBalanceCard from "./default/OverallBalanceCard.vue";
import RecentOrdersCard from "./default/RecentOrdersCard.vue";
import ActivitiesCard from "./default/ActivitiesCard.vue";
import RecentRegistrationsCard from "./thaurusguru/RecentRegistrationsCard.vue";
import TimelineCard from "./default/TimelineCard.vue";
import ProAccountCard from "./default/ProAccountCard.vue";
import TotalUsersGrowthCard from "./default/TotalUsersGrowthCard.vue";
import PaperNoteCard from "./default/PaperNoteCard.vue";

export default {
    components: {
        StatCards,
        // OrdersProfitCard,
        // OverallBalanceCard,
        // RecentOrdersCard,
        // ActivitiesCard,
        RecentRegistrationsCard,
        // TimelineCard,
        // ProAccountCard,
        // TotalUsersGrowthCard,
        // PaperNoteCard,
    },
};
</script>
