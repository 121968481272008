<template>
    <div class="col-sm-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <h3>Material style right tab</h3><span>Add <code>.border-tab</code> class with nav class</span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-9">
                <div class="tab-content text-end" id="v-pills-right-tabContent">
                  <div class="tab-pane fade show active" id="v-pills-right-home" role="tabpanel" aria-labelledby="v-pills-right-home-tab">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                  </div>
                  <div class="tab-pane fade" id="v-pills-right-profile" role="tabpanel" aria-labelledby="v-pills-right-profile-tab">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                  </div>
                  <div class="tab-pane fade" id="v-pills-right-messages" role="tabpanel" aria-labelledby="v-pills-right-messages-tab">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                  </div>
                  <div class="tab-pane fade" id="v-pills-right-settings" role="tabpanel" aria-labelledby="v-pills-right-settings-tab">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 tabs-responsive-side line-tab-right">
                <div class="nav flex-column border-tab nav-right" id="v-pills-right-tab" role="tablist" aria-orientation="vertical"><a class="nav-link active" id="v-pills-right-home-tab" data-bs-toggle="pill" href="#v-pills-right-home" role="tab" aria-controls="v-pills-right-home" aria-selected="true">Home</a><a class="nav-link" id="v-pills-right-profile-tab" data-bs-toggle="pill" href="#v-pills-right-profile" role="tab" aria-controls="v-pills-right-profile" aria-selected="false">Profile</a><a class="nav-link" id="v-pills-right-messages-tab" data-bs-toggle="pill" href="#v-pills-right-messages" role="tab" aria-controls="v-pills-right-messages" aria-selected="false">Inbox</a><a class="nav-link" id="v-pills-right-settings-tab" data-bs-toggle="pill" href="#v-pills-right-settings" role="tab" aria-controls="v-pills-right-settings" aria-selected="false">Settings</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-xl-6">
        <div class="card">
          <div class="card-header">
            <h3>Material color tab</h3><span>Add <code>.nav-primary</code> class with nav class</span>
          </div>
          <div class="card-body">
            <ul class="nav nav-tabs border-tab nav-primary" id="info-tab" role="tablist">
              <li class="nav-item"><a class="nav-link active" id="info-home-tab" data-bs-toggle="tab" href="#info-home" role="tab" aria-controls="info-home" aria-selected="true"><i class="icofont icofont-ui-home"></i>Home</a></li>
              <li class="nav-item"><a class="nav-link" id="profile-info-tab" data-bs-toggle="tab" href="#info-profile" role="tab" aria-controls="info-profile" aria-selected="false"><i class="icofont icofont-man-in-glasses"></i>Profile</a></li>
              <li class="nav-item"><a class="nav-link" id="contact-info-tab" data-bs-toggle="tab" href="#info-contact" role="tab" aria-controls="info-contact" aria-selected="false"><i class="icofont icofont-contacts"></i>Contact</a></li>
            </ul>
            <div class="tab-content" id="info-tabContent">
              <div class="tab-pane fade show active" id="info-home" role="tabpanel" aria-labelledby="info-home-tab">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
              </div>
              <div class="tab-pane fade" id="info-profile" role="tabpanel" aria-labelledby="profile-info-tab">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
              </div>
              <div class="tab-pane fade" id="info-contact" role="tabpanel" aria-labelledby="contact-info-tab">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
              </div>
            </div>
          </div>
        </div>
      </div>
     
</template>