<template>
    <div class="col-sm-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h5>Tags With icon</h5>
                    </div>
                    <div class="card-body">
                        <a class="badge badge-primary" href="#"><i data-feather="dollar-sign">
                                <vue-feather type="dollar-sign"></vue-feather>
                            </i></a>
                        <a class="badge badge-secondary" href="#"><i data-feather="headphones">
                                <vue-feather type="headphones"></vue-feather>
                            </i></a>
                        <a class="badge badge-success" href="#"><i data-feather="link">
                                <vue-feather type="link"></vue-feather>
                            </i></a>
                        <a class="badge badge-info" href="#"><i data-feather="github">
                                <vue-feather type="github"></vue-feather>
                            </i></a>
                        <a class="badge badge-warning" href="#"><i data-feather="award">
                                <vue-feather type="award"></vue-feather>
                            </i></a>
                        <a class="badge badge-danger" href="#"><i data-feather="activity">
                                <vue-feather type="activity"></vue-feather>
                            </i></a>
                        <a class="badge badge-light text-dark" href="#"><i data-feather="heart">
                                <vue-feather type="heart"></vue-feather>
                            </i></a>
                        <a class="badge badge-dark" href="#"><i data-feather="mail">
                                <vue-feather type="mail"></vue-feather>
                            </i></a>
                    </div>
                </div>
            </div>
</template>